import logoVanCleef from "../_assets/Van-Cleef-Arpels-logo.png";
import logoJewelsForEternity from "../_assets/jewelsforeternity-logo.png";

export const awsRepoImg = (cluster_hash: string, path: string) => {
  const s3Repo = `https://db-luxe-images.s3.eu-west-3.amazonaws.com/${cluster_hash}/${path}`;
  return s3Repo;
};


export const getLogo = (themeName: string) => {
  return themeName === "van-cleef" ? logoVanCleef : themeName === "vancleefdbluxe" ? logoVanCleef : logoJewelsForEternity;
} 

export const isVanfCleef = (themeName: string) => {
  return themeName === "van-cleef" || themeName === "vancleefdbluxe";
} 

export const isVanfCleefProcess = () => {
  return process.env.REACT_APP_THEME === "van-cleef" || process.env.REACT_APP_THEME === "vancleefdbluxe"
} 


