import { makeStyles } from "@material-ui/core";

export const loginStyles = makeStyles(() => {
  return {
    item: {
      display: "flex",
      justifyContent: "center",
      padding: "0.5rem",
    },
    link: {
      color: "black",
    },
  };
});
