export enum RequestParamName {
  PAGE = "page",
  LIMIT = "limit",
  SORT_BY = "sort_by",
  KEYWORD = "keyword",
  SEARCH_MODE = "search_mode",
  PRICE_MIN = "price_min",
  PRICE_MAX = "price_max",
  TREATED = "treated",
  DATE_MIN = "date_min",
  DATE_MAX = "date_max",
  DATE_MANUFACTURE_MIN = "date_manufacture_min",
  DATE_MANUFACTURE_MAX = "date_manufacture_max",
  CURRENCY = "currency",
  STONE_NAME = "stone",
  CARAT_MIN = "carat_min",
  CARAT_MAX = "carat_max",
  ORIGINS = "origins",
  COLORS = "colors",
  COLORGRADE = "colorgrade",
  CLARITY = "clarity",
  CUTS = "cuts",
  CERTIFICATIONS = "certifications",
  SOLD = "sold",
  SOURCE = "source",
  CREATION_TYPES = "creation_types",
  CATEGORIES = "categories",
  BRANDS="brands",
}

