import { isVanfCleefProcess } from "helpers/constants";
import { MultiSelectRecord } from "models/Filters.interface";

export const createRecordFromNames =
  (names: Array<string>, suggestedNames?: Array<string>, hideVanCleefNames?: Array<string>): MultiSelectRecord => {
    return names.reduce((acc: MultiSelectRecord, name: string) => {
      const param = createParamFromName(name);
      if (isVanfCleefProcess() && hideVanCleefNames?.includes(name)) {
        return acc
      }
      acc[param] = {
        label: name,
        paramName: param,
        dtoValue: param,
        isSuggested: suggestedNames ? suggestedNames.includes(name) : false
      }
      return acc;
    }, {})
  }

export const createParamFromName = (name: string): string => {
  /* let param = name.replace(/ /g, '_');
  param = param.replace(/’/g, '');
  param = param.replace(/'/g, '');
  param = param.replace(/&/g, '');
  param = param.replace(/\//g, '');
  param = param.replace(/__/g, '_'); */
  return encodeURIComponent(name);
}
