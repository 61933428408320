import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { MultiSelectRecord, FilterCheckboxListProps } from "models/Filters.interface";
import React from "react";
import { WrappedFieldProps } from "redux-form";


const getSuggestedParams = (recordList: FilterCheckboxListProps): Array<string> => {
  return Object.values(recordList)
    .filter((brand: FilterCheckboxListProps) => brand.isSuggested)
    .map((brand: FilterCheckboxListProps) => brand.paramName)
}

export interface RenderAutoCompleteProps {
  recordList: MultiSelectRecord;
  multiple?: boolean;
}


// garder le any sinon probleme de TS
const RenderAutoComplete: React.FC<WrappedFieldProps & TextFieldProps & RenderAutoCompleteProps & any> = ({
  placeholder,
  label,
  input,
  meta: { touched, invalid, error },
  recordList,
  multiple,
  ...custom
}) => {
  const options = Object.values(recordList as FilterCheckboxListProps).map((value: FilterCheckboxListProps) => (value).paramName);
  const suggestedList = getSuggestedParams(recordList)
  const defaultFilterOptions = createFilterOptions();
  const handleKeyDown = (event: any) => {
    // do not send form on enter
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  const filterOptions = (options: any, state: any) => {
    if (suggestedList && state?.inputValue === "") {
      return defaultFilterOptions(suggestedList, state);
    }
    return defaultFilterOptions(options, state);
  };

  function handleChange(stone: string | Array<string>) {
    input.onChange(stone);
  }

  const getLabel = (val: string) => {
    return recordList[val].label;
  }

  return (
    <div className="vca-textfield">
      {typeof label === 'string' && label.length > 0 && (
        <label className="vca-textfield__label">{label}</label>
      )}
      <div className="vca-textfield__field vca-textfield__field--autocomplete">
        {!multiple ? (
          <Autocomplete
            filterOptions={filterOptions}
            noOptionsText="Start typing..."
            options={options}
            value={input.value || null}
            getOptionLabel={getLabel}
            onChange={(event, stone: string) => {
              handleChange(stone);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                variant="outlined"
                error={touched && invalid}
                helperText={touched && error}
                onKeyDown={handleKeyDown}
              />
            )}
            {...custom}
          />
        ) : (
          <Autocomplete
            multiple
            filterOptions={filterOptions}
            noOptionsText="Start typing..."
            options={options}
            disableClearable
            value={input.value || []}
            filterSelectedOptions
            getOptionLabel={getLabel}
            onChange={(event, values: Array<string>) => {
              handleChange(values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                variant="outlined"
                error={touched && invalid}
                helperText={touched && error}
                onKeyDown={handleKeyDown}
              />
            )}
            {...custom}
          />
        )}

      </div>
    </div>
  );
};


export default RenderAutoComplete;
