import HTTPClient from "httpClient/dataClient";
import { Dto } from "models/Dto/Dto.interface";
import { JewelDto, JewelResultsDto } from "models/Dto/JewelsDto.interface";
import { useCallback, useEffect, useState } from "react";


interface UseGetJewelryProps {
  jewelId?: string | undefined;
}


interface UseGetJewelryDataResult {
  data: JewelDto | null;
  loading: boolean|undefined;
  error: Error | null;
  doFetch: (jewelId: string | undefined) => void;
}

const fetchJewelry = async (id: string|undefined): Promise<JewelDto> => {
  try {
    const response: Dto<JewelResultsDto> = await HTTPClient.createClient().get(`/jewelry-list/${id}`);
    console.log(response.data.data[0])
    return response.data.data[0]
  } catch (error: any) {
    console.error(error)
    return error
  }
}

const useGetJewelry = ({ jewelId }: UseGetJewelryProps): UseGetJewelryDataResult => {

  const [data, setData] = useState<JewelDto | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<Error | null>(null);

  const doFetch = useCallback((id = jewelId) => {
    const fetchJewelryLocal = async () => {
      setLoading(true);
      const response: JewelDto = await fetchJewelry(id);
      if(response){
        setData(response);
      } else {
        setError(response as any);
      }
      setLoading(false);
    }
    fetchJewelryLocal()
  }, [jewelId])

  useEffect(() => {
    if (jewelId) {
      doFetch()
    }

  }, []);

  return { data, loading, error, doFetch };

}

export default useGetJewelry;