import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { isVanfCleef } from "helpers/constants";
import { ProductStoreSearch } from "models/Store/ProductStore.interface";
import { getProductStoreMapper, getProductStoreSearchMapper } from "models/Store/ProductStoreMapper";
import { initialize } from "redux-form";
import { themeNameSelector } from "store/application/ApplicationSelectors";
import { getDefaultSortBy } from "utils/filters.utils";
import {
  getPrintProducts,
  getSearchProductsList
} from "../../store/product/ProductActions";
import {
  productsListSelector,
  searchFormDataSelector,
} from "../../store/product/ProductSelectors";
import { STORE } from "store/models/Store.enum";

export const useCategoryValues = () => {
  const themeName = useSelector(themeNameSelector);
  const isVanCleef = isVanfCleef(themeName);

  const dispatch = useDispatch();
  const [sortby, setSortby] = useState(getDefaultSortBy(isVanCleef));
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [errorClassSpecificPage, setErrorClassSpecificPage] = useState("");

  const history = useHistory();

  const backToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSpecificPagination = (e: any) => {
    e.preventDefault();
    const pageToGet = e.target.paginationText.value;
    if (pageToGet > totalPages) {
      setErrorClassSpecificPage("error");
    } else {
      setErrorClassSpecificPage("");
      handleChangePage(e, pageToGet)
    }
  }

  const handleSortby = (event: any) => {
    const data = infoData();
    setSortby(event.target.value);
    dispatch(
      getSearchProductsList.request({
        ...data,
        page: 1,
        sort_by: event.target.value,
      })
    );
    backToTop();
  };



  const body = document.querySelector("body");

  const openFilters = () => {
    setFilterOpen(true);
    body?.classList.add("stop-scroll");
  };

  const closeFilters = useCallback(() => {
    setFilterOpen(false);
    body?.classList.remove("stop-scroll");
  }, []);

  history.listen(() => {
    body?.classList.remove("stop-scroll");
  });

  const searchFormData: ProductStoreSearch = useSelector(searchFormDataSelector);

  const infoData = (): ProductStoreSearch => getProductStoreSearchMapper(searchFormData, isVanCleef);

  const getKeywords = (): Array<string> => {
    const search = searchFormData.keyword ?? undefined;
    return search?.split(",").map((word: string) => word.trim());
  };

  useEffect(() => {
    const data = infoData();
    dispatch(
      getSearchProductsList.request({
        ...data,
      })
    );
    backToTop();
  }, [dispatch]);

  const handleChangePage = (event: any, value: number) => {
    const data = infoData();
    dispatch(
      getSearchProductsList.request({
        ...data,
        page: value,
      })
    );
    backToTop();
  };

  const productsList = useSelector(productsListSelector);
  const totalPages = !productsList.total_pages ? 0 : productsList.total_pages <= 100 ? productsList.total_pages : 100;

  const printProducts = () => {
    const data = infoData();
    dispatch(getPrintProducts.request(data));
  };

  const clearSearchForm = () => {
    //TODO Select All
    //dispatch(change(STORE.SEARCH_FORM, "marketplaces.therealreal", false))
    //dispatch(change(STORE.SEARCH_FORM, "marketplaces.1stdibs", false))
    dispatch(initialize(STORE.SEARCH_FORM, getProductStoreMapper(undefined)));
    //dispatch(getSearchProductsList.request()); // do request
    //closeFilters();
  };

  return {
    productsList,
    totalPages,
    sortby,
    isFilterOpen,
    searchFormData,
    printProducts,
    handleSortby,
    openFilters,
    closeFilters,
    handleChangePage,
    clearSearchForm,
    getKeywords,
    handleSpecificPagination,
    errorClassSpecificPage
  };
};
