import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ActionsDuplicatas from "../ActionsDuplicatas/ActionsDuplicatas";
import { DuplicataUserValues } from "../Duplicata.interfaces";
import JewelDuplicata from "../JewelDuplicata/JewelDuplicata";
import useFetchDuplicata, { DuplicatasTypes } from "../useFetchDuplicata";
import useFetchDuplicataJewels from "../useFetchDuplicataJewels";
import usePostDuplicates from "../usePostDuplicates";

interface JewelDuplicatasProps {
  duplicataType: DuplicatasTypes,
}

const JewelDuplicatas: React.FC<JewelDuplicatasProps> = ({
  duplicataType,
}) => {
  const [currentJewelInList, setCurrentJewelInList] = useState<number>(0);
  const { data: dataDuplicatas, loading: loadingDuplicatas, error: errorDuplicatas } = useFetchDuplicata(duplicataType);
  const { postDuplicates, loading: loadingPostDuplicate } = usePostDuplicates();
  const [error, setError] = useState<boolean>(false)

  const {
    doFetch,
    dataJewelLeft,
    dataJewelRight,
    loadingJewelLeft,
    errorJewelLeft,
    loadingJewelRight,
    errorJewelRight
  } = useFetchDuplicataJewels();


  useEffect(() => {
    setError(false)
    if (duplicataType) {
      setCurrentJewelInList(0);
    }
  }, [duplicataType])

  useEffect(() => {
    if (dataDuplicatas) {
      fetchJewels();
    }
  }, [dataDuplicatas])

  useEffect(() => {

    if (currentJewelInList > 0 && currentJewelInList < 10 && currentJewelInList < dataDuplicatas?.data?.length) {
      fetchJewels();
    } else {
      setCurrentJewelInList(0)
    }
  }, [currentJewelInList])

  const fetchJewels = () => {
    if (dataDuplicatas?.data?.length > 0) {
      doFetch({
        duplicatas: dataDuplicatas,
        currentJewelInList: currentJewelInList
      })
    } else {
      setError(true)
    }
  }

  const isLoading: boolean = loadingDuplicatas || loadingPostDuplicate; 
  const hasError: boolean = !!errorDuplicatas || !!errorJewelLeft || !!errorJewelRight || error;
  const isLoadingJewels: boolean = loadingJewelLeft || loadingJewelRight; 
  
  const isButtonDisabled: boolean =
    isLoading ||
    hasError ||
    dataDuplicatas?.data?.length === 0 ||
    isLoadingJewels

  const handlePostDuplicate = (userValue: DuplicataUserValues) => {
    if (dataJewelLeft && dataJewelRight) {
      postDuplicates({
        userValue,
        duplicateTuple: [dataJewelLeft?.id, dataJewelRight?.id]
      }).then(response => {
        console.log(response)
        setCurrentJewelInList(currentJewelInList + 1)
      }).catch((error: any) => {
        console.error(error);
      })
    }
  }

  const typeName = duplicataType === DuplicatasTypes.DUPLICATA ? 'Duplicatas' : 'Sold Twice';

  let content;

  if (isLoading) {
    content = (
      <div className="duplicatas-loading">
        <div className="duplicatas-loading__loader">
          <CircularProgress />
        </div>
        <p className="heading-1 center" style={{ fontWeight: 600, marginBottom: 10 }}>
          {loadingPostDuplicate ? 'Updating...' : 'Loading...'}
        </p>
        <p className="heading-3 center mb-20px">
          {loadingDuplicatas ? typeName : ''}
        </p>
      </div>
    );
  } else if (hasError) {
    content = <p className="heading-3 center mt-50px mb-20px">An error occured...</p>;
  } else if (dataDuplicatas?.data?.length === 0) {
    content = <p className="heading-3 center mt-50px mb-20px">No {typeName} found</p>;
  } else {
    content = (
      <div className="duplicatas">
        <div className="duplicatas-col">
          <JewelDuplicata jewel={dataJewelLeft} loading={loadingJewelLeft} />
        </div>
        <div className="duplicatas-col">
          <JewelDuplicata jewel={dataJewelRight} loading={loadingJewelRight} />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="page-duplicatas__body vca-container-page-mw w-100pct">
        {content}
      </div>
      <div className="duplicatas__btns w-100pct">
        <ActionsDuplicatas
          handlePostDuplicate={handlePostDuplicate}
          setCurrentJewelInList={setCurrentJewelInList}
          currentJewelInList={currentJewelInList}
          disabled={isButtonDisabled}
        />
      </div>
    </>
  )
}


export default JewelDuplicatas;