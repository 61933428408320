import useGetJewelry from "hooks/useGetJewelry";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import { useCallback } from "react";
import { Duplicatas } from "./Duplicata.interfaces";

interface UseDuplicataDataResult {
  doFetch: (params: FetchDuplicataProps) => void;
  dataJewelLeft: JewelDto | undefined;
  loadingJewelLeft: boolean | undefined;
  errorJewelLeft: Error | undefined;
  dataJewelRight: JewelDto | undefined;
  loadingJewelRight: boolean | undefined;
  errorJewelRight: Error | undefined
}

interface FetchDuplicataProps {
  duplicatas: Duplicatas;
  currentJewelInList: number;
}

const useFetchDuplicataJewels = (): UseDuplicataDataResult => {
  
  const { data: dataJewelLeft, loading: loadingJewelLeft, error: errorJewelLeft, doFetch: fetchJewelLeft } = useGetJewelry({});
  const { data: dataJewelRight, loading: loadingJewelRight, error: errorJewelRight, doFetch: fetchJewelRight } = useGetJewelry({});

  const doFetch = useCallback((
    { duplicatas, currentJewelInList }: FetchDuplicataProps
  ) => {
    fetchJewelLeft(duplicatas.data[currentJewelInList][0])
    fetchJewelRight(duplicatas.data[currentJewelInList][1])
  }, [])

  return {
    doFetch,
    dataJewelLeft,
    dataJewelRight,
    loadingJewelLeft,
    errorJewelLeft,
    loadingJewelRight,
    errorJewelRight
  };

}

export default useFetchDuplicataJewels;