import DateFnsUtils from "@date-io/date-fns";
import { FormControl } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import RenderSelectDatePicker from "components/shared/form/RenderSelectDatePicker";
import React, { useState } from "react";
import { Field } from "redux-form";
import FilterWrapper, { FilterWrapperBorderProps } from "./shared/FilterWrapper";
import { useSelector } from "react-redux";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import { ProductStoreSearch } from "models/Store/ProductStore.interface";

const FilterDateOfManufacture: React.FC<FilterWrapperBorderProps> = ({
  borderBottom,
}) => {
  const searchFormData: ProductStoreSearch = useSelector(searchFormDataSelector);
  const minDateManufacture = new Date(1000, 0, 1);
  const [selectedDateManufactureFirst, handleDateManufactureFirstChange] = useState<Date>(searchFormData.date_manufacture.date_manufacture_min);
  const [selectedDateManufactureLast, handleDateManufactureLastChange] = useState<Date>(searchFormData.date_manufacture.date_manufacture_max);
  return (
    <FilterWrapper borderBottom={borderBottom}>
     <h3 className="heading-3">Date of Manufacture</h3>
            <div className="search-panel__double-row">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="vca-textfield">
                  <div className="vca-textfield__label">From</div>
                  <div className="vca-textfield__field is-datepicker-component">
                    <FormControl>
                      <Field
                        component={RenderSelectDatePicker}
                        name="date_manufacture.date_manufacture_min"
                        variant="inline"
                        minDate={minDateManufacture}
                        maxDate={selectedDateManufactureLast || new Date()}
                        views={["year"]}
                        formatDate="yyyy"
                        onChange={(date: any) =>
                          handleDateManufactureFirstChange(date)
                        }
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="vca-textfield">
                  <div className="vca-textfield__label">to</div>
                  <div className="vca-textfield__field is-datepicker-component">
                    <FormControl>
                      <Field
                        component={RenderSelectDatePicker}
                        name="date_manufacture.date_manufacture_max"
                        variant="inline"
                        minDate={
                          selectedDateManufactureFirst || minDateManufacture
                        }
                        maxDate={new Date()}
                        views={["year"]}
                        formatDate="yyyy"
                        onChange={(date: any) =>
                          handleDateManufactureLastChange(date)
                        }
                      />
                    </FormControl>
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
    </FilterWrapper>
  )
}

export default FilterDateOfManufacture;