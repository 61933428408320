import { createSelector } from "reselect";
import get from "lodash/get";

const applicationSelector = (state) => get(state, "application");

export const isReadySelector = createSelector(
  applicationSelector,
  (application) => get(application, "isReady", false)
);

export const themeNameSelector = createSelector(
  applicationSelector,
  (application) => get(application, `themeName`, undefined)
);

export const isNotifiedSelector = createSelector(
  applicationSelector,
  (application) => get(application, "notification.isNotified")
);

export const notificationMessageSelector = createSelector(
  applicationSelector,
  (application) => get(application, "notification.message")
);

export const messageTypeSelector = createSelector(
  applicationSelector,
  (application) => get(application, "notification.messageType")
);

export const isLoadingDataSelector = (actionName) =>
  createSelector(applicationSelector, (application) =>
    get(application, `isLoadingData.${actionName}`, false)
  );

export const isFetchingSelector = createSelector(
  applicationSelector,
  (application) => get(application, `isLoadingData`, false)
);

export const emailForgotPasswordSelector = createSelector(
  applicationSelector,
  (application) => get(application, `emailForgotPassword`, null)
);