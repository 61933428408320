import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "models/Store/ProductStore.utils";

export const getClarityListRecord = (): MultiSelectRecord => createRecordFromNames(clarityListNames);

export enum ClarityNames {
  OTHER = "Other",
}

const clarityListNames: Array<string> = [
  "FL",
  "IF",
  //"VVS",
  "VVS1",
  "VVS2",
  //"VS",
  "VS1",
  "VS2",
  // "SI",
  // "SI1",
  // "SI2",
  // "SI3",
  // "I",
  // "I1",
  // "I2",
  // "I3",
  // "P",
  // "P1",
  // "P2",
  // "P3",
  ClarityNames.OTHER,
]
