import HTTPClient from "httpClient/dataClient";
import { Dto } from "models/Dto/Dto.interface";
import { useEffect, useState } from "react";
import { Duplicatas } from "./Duplicata.interfaces";

interface UseDuplicataDataResult {
  data: Duplicatas | null;
  loading: boolean;
  error: Error | null;
  //fetchData: () => void;
}

export enum DuplicatasTypes {
  DUPLICATA = "duplicatas",
  SOLD_TWICE = "sold twice",
}

const duplicataEndpoints: Record<DuplicatasTypes, string> = {
  [DuplicatasTypes.DUPLICATA]: "/duplicates",
  [DuplicatasTypes.SOLD_TWICE]: "/sold_twice",
}


const useFetchDuplicata = (type: DuplicatasTypes): UseDuplicataDataResult => {

  const [data, setData] = useState<Duplicatas | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async (abortController: AbortController) => {
    try {
      if(!type){
        return
      }

      setLoading(true);
      setError(null);
      const response: Dto<Duplicatas> = await HTTPClient.createClient().get(duplicataEndpoints[type], {
        signal: abortController.signal 
      });
      setData(response.data);
    } catch (error: any) {
      console.error(error)
      setError(error);
    } finally {
      setLoading(false);
    }

  };

  
  useEffect(() => {
    const abortController = new AbortController();
    setTimeout(() => {
      fetchData(abortController)
    }, 0);
    
    
    return () => {
      abortController.abort();
      // Nettoyage ou annulation de la requête en cours
    };
  }, [type]);

  return { data, loading, error };

}

export default useFetchDuplicata;