import { ProductStoreDateManufacture, ProductStoreDateSale } from "models/Store/ProductStore.interface";
import { RequestParamName } from "store/models/Request.enum";

export const dateRequestParams = (date: ProductStoreDateSale): string => {

  const paramDateMin =
    date.date_min
      ? `${RequestParamName.DATE_MIN}=${date.date_min.toLocaleDateString("fr-CA")}&`
      : ``;

  const paramDateMax =
    date.date_max
      ? `${RequestParamName.DATE_MAX}=${date.date_max.toLocaleDateString("fr-CA")}&`
      : ``;

  return `${paramDateMin}${paramDateMax}`
}


export const dateManufactureRequestParams = (dateManufacture: ProductStoreDateManufacture): string => {
  const paramDateManufactureMin =
    dateManufacture.date_manufacture_min
      ? `${RequestParamName.DATE_MANUFACTURE_MIN}=${dateManufacture.date_manufacture_min.getFullYear()}&`
      : ``;

  const paramDateManufactureMax =
    dateManufacture.date_manufacture_max
      ? `${RequestParamName.DATE_MANUFACTURE_MAX}=${dateManufacture.date_manufacture_max.getFullYear()}&`
      : ``;

  return `${paramDateManufactureMin}${paramDateManufactureMax}`
}

