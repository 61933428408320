import React, { ReactNode } from 'react';

export interface FilterWrapperBorderProps {
  borderBottom?: boolean;
  isSubFilter?: boolean;
}

interface FilterWrapperProps extends FilterWrapperBorderProps {
  children: ReactNode;
}

const FilterWrapper: React.FC<FilterWrapperProps> = ({ children, borderBottom, isSubFilter }) => {
  const classBorderBottom: string = borderBottom ? "has-border-bottom" : "";
  const classBorderLeft: string = isSubFilter ? "has-sub-filter" : ""
  return (
    <div className={`search-panel__item ${classBorderBottom} ${classBorderLeft}`}>
      {children}
    </div>
  );
};

export default FilterWrapper;