import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const RenderTextField: React.FC<any> = ({
  placeholder,
  label,
  input,
  type,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <div className="vca-textfield">
    {label && label.length > 0 && <label className="vca-textfield__label">{label}</label>}
    <div className="vca-textfield__field">
      <TextField
        placeholder={placeholder}
        type={type}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </div>
  </div>
);

RenderTextField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderTextField.defaultProps = {
  placeholder: "",
  label: "",
  meta: {},
  type: "",
};

export default RenderTextField;
