import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_SEARCH_FORM_DATA } from "store/product/ProductActions";
import { searchFormDataSelector, sortBySelector } from "store/product/ProductSelectors";

interface UseSearchPanelProps {
  onCloseFilters: () => void;
  isFilterOpen: boolean;
}

export const useSearchPanel = ({ onCloseFilters, isFilterOpen }: UseSearchPanelProps) => {
  const dispatch = useDispatch();
  const sort_by = useSelector(sortBySelector);
  const [selectedDefaultSortBy, handleDefaultSortByChange] = useState<string>();
  const searchFormData = useSelector(searchFormDataSelector);

  useEffect(() => {
    if (isFilterOpen) {
      handleDefaultSortByChange(sort_by);
    }
  }, [isFilterOpen]);

  const closeFilters = (isCloseButton: boolean) => {
    onCloseFilters();
    if (isCloseButton) {
      dispatch({ type: SAVE_SEARCH_FORM_DATA, ...searchFormData, sort_by: selectedDefaultSortBy });
    }
  };


  return {
    closeFilters
  }
}
