import { isVanfCleefProcess } from "helpers/constants";
import { isBoolean } from "../../helpers/utils";

export const DEFAULT_STRICK_KEYWORD = true;

export const defaultStrictKeywords = (data_search_mode) => {
  if(isVanfCleefProcess()){
    return isBoolean(data_search_mode) ? data_search_mode : true
  }
  return false
}
