import get from "lodash/get";
import { createSelector } from "reselect";
import { STORE_SEARCH_FORM_PARAMS } from "store/models/Store.enum";

const productSelector = (state: any) => get(state, "product");

export const productsListSelector = createSelector(productSelector, (product) =>
  get(product, "productsList")
);

export const productInfoSelector = createSelector(productSelector, (product) =>
  get(product, "productInfo")
);

export const searchFormDataSelector = createSelector(
  productSelector,
  (product) => get(product, "searchFormData")
);


export const sortBySelector = createSelector(
  searchFormDataSelector,
  (searchFormData) => get(searchFormData, STORE_SEARCH_FORM_PARAMS.SORT_BY)
);

export const currencySelector = createSelector(
  searchFormDataSelector,
  (searchFormData) => get(searchFormData, STORE_SEARCH_FORM_PARAMS.CURRENCY)
);

export const printProductsSelector = createSelector(
  productSelector,
  (product) => get(product, "printProducts")
);

export const productsRequestsHistorySelector = createSelector(
  productSelector,
  (product) => get(product, "productsRequestHistory")
);
