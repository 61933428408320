import React from "react";
import { reduxForm, Field } from "redux-form";
import {
  Button,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import RenderTextField from "components/shared/form/RenderTextField";
import RenderSelectDatePicker from "components/shared/form/RenderSelectDatePicker";
import "./productForm.scss"

const ProductForm = ({ initialValues, handleSubmit, history }) => {

  return (
    <div className="page-product-form vca-container-page">
      <form className="product-form" onSubmit={handleSubmit}>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Title"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="title"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Signature"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="signature"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Lot Number"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="lotNumber"
                
                variant="outlined"
                size="small"
                type="number"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Property From"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="propertyFrom"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Final Sale Price"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                className="price-field"
                component={RenderTextField}
                name="priceRealized"
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{" €"}</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Estimate"}
            </div>
          </div>
          <div className="product-form__field multiple-rows">
          <FormControl>
              <Field
                className="price-field"
                component={RenderTextField}
                name="priceEstimateLow"
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{" €"}</InputAdornment>
                  ),
                }}
              />
            </FormControl>
              <div className="paragraph">
              {" - "}
            </div>
            <FormControl>
              <Field
                className="price-field"
                component={RenderTextField}
                name="priceEstimateHigh"
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{" €"}</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Auction House"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="auctionHouse"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Sale"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="sale"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Place"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="place"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Date"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderSelectDatePicker}
                name="date"
                
                variant="inline"
                size="small"
                views={["day", "month", "year"]}
                defaultValue={
                  initialValues?.date
                    ? new Date(initialValues.date)
                    : new Date()
                }
                fullDate
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Description"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="description"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Lot Essay"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="lotEssay"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Individual"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="individual"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"PostLotText"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="postLotText"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Litterature"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="description"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Provenance"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="provenance"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Condition Report"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="conditionReport"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Sale Room Notice"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="saleRoomNotice"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"Special Notice"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="specialNotice"
                className="field-textarea"
                variant="outlined"
                size="small"
                multiline
                rows={6}
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"URL Sale"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="urlSale"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__row">
          <div className="product-form__label">
            <div className="paragraph">
              {"URL Lot"}
            </div>
          </div>
          <div className="product-form__field">
            <FormControl>
              <Field
                component={RenderTextField}
                name="urlLot"
                
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
        </div>
        <div className="product-form__buttons">
          <div className="wrap-btn center">
            <Button
              className="vca-btn vca-btn--link"
              variant="outlined"
              onClick={() => history.push(`/category`)}
            >
              {"Cancel"}
            </Button>
            <Button
              className="vca-btn"
              type="submit"
              variant="contained"
            >
              {"SAVE"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const formConfig = {
  form: "PRODUCT_FORM",
  //validate,
  onSubmit: (values) => {
    console.info(values);
  },
};

export default reduxForm(formConfig)(ProductForm);
