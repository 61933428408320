import React, { useRef } from 'react';

const PriceTooltip = (props) => {

  const DEFAULT_PADDING = 20;

  let cssClass = 'tooltip';
  cssClass += props.tooltipposition ? ` tooltip--${props.tooltipposition}` : ''
  const tooltipHoverRef = useRef(null);

  const resizeTooltip = () => {
    // Only works for tootlip on the right at this moment
    const tooltipRect = tooltipHoverRef.current.getBoundingClientRect();
    const tooltipRight = tooltipRect.right;
    const windowW = window.innerWidth || document.documentElement.clientWidth;

    //resize width
    const tooltipWidth = tooltipRect.width;
    let newTooltipWidth = tooltipWidth;
    
    if(tooltipWidth > windowW){
      newTooltipWidth = windowW - (DEFAULT_PADDING * 2)
      tooltipHoverRef.current.style.width = `${newTooltipWidth}px`
    }

    //position right
    if(tooltipRight > windowW){
      const newTooltipRight = tooltipRect.left + newTooltipWidth;
      const rightP = windowW - newTooltipRight - DEFAULT_PADDING; // negative number
      tooltipHoverRef.current.style.transform = `translate3d(${rightP}px,0,0)`
    }
  }

  const resizeDefaultTooltip = () => {
    tooltipHoverRef.current.style.transform = null;
    tooltipHoverRef.current.style.width = null
  }

  
  return (
  <div className={cssClass} onMouseEnter={resizeTooltip} onMouseLeave={resizeDefaultTooltip}>
    <div className="tooltip__hovered" ref={tooltipHoverRef}>
      The exchange rates used for the price & estimates conversion are the end-of-day rates of the date of the sale. 
      <br />
      <br />
      Converted estimates are rounded to match the original estimates. 
      <br />
      <br />
      The price realized is inclusive of the Auction house’s buyer’s premium (between 26% and 12,5% of the hammer price, depending on the auction houses).
      </div>
    <div className="circle-icon circle-icon--small">i</div>
  </div>
)}

export default PriceTooltip;