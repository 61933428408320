
export interface UserDto { 
  user: User;
}


export interface User {
  email: string;
  id: string;
  permissions: Array<UserPermissions>;
  request: any;
}

export enum UserPermissions {
  ADMIN = "admin",
  ALL = "all",
  SUPERUSER = "superuser",
  VINTAGE = "vintage",
  LIMITED = "limited",
}