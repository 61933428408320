import Duplicatas from "components/duplicatas/page";
import { UserPermissions } from "models/User.interface";
import NoRightsPage from "pages/NoRightsPage";
import NotFoundPage from "pages/NotFoundPage";
import { useAuth } from "providers/AuthContext";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../layout/Layout";
import CategoryPage from "../pages/CategoryPage";
import ProductFormPage from "../pages/ProductForm";
import ViewProductPage from "../pages/ViewProductPage";

const RoutesPrivate: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route
          exact
          path={"/category"}
          render={(props: any) => <CategoryPage {...props} />}
        />
        <Route
          exact
          path={"/view-product/:productId?"}
          render={(props: any) => <ViewProductPage {...props} />}
        />
        <Route
          exact
          path={"/create-product"}
          render={(props: any) => <ProductFormPage {...props} />}
        />
        <Route
          exact
          path={"/edit-product/:productId?"}
          render={(props: any) => <ProductFormPage {...props} />}
        />
        <RoutesPrivatePermissions
          permissions={[UserPermissions.ADMIN]}
          path="/duplicatas"
          page={Duplicatas}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  )
};


// TODO export dans un autre fichier
const RoutesPrivatePermissions: React.FC<{ permissions: Array<UserPermissions>, path: string, page: React.ComponentType<any> }> =
  ({ permissions, path, page: PageComponent }) => {
    const { hasPermissions} = useAuth();
    return (
      <Switch>
        <Route
          exact
          path={path}
          render={(props: any) => hasPermissions(permissions) ? <PageComponent {...props} /> : <NoRightsPage {...props} />}
        />
      </Switch>
    )
  };


export default RoutesPrivate;