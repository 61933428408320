import React from "react";
import { reduxForm, Field, reset } from "redux-form";
import { Button, FormControl } from "@material-ui/core";
import validate from "../../shared/validated-form/LoginValidation";
import RenderTextField from "../../shared/form/RenderTextField";
import { getTokenForgotPassword } from "../../../store/application/ApplicationActions";


const ForgotPasswordToken = ({ handleSubmit }) => {

  return (
    <div className="">
      <form className="vca-form" onSubmit={handleSubmit}>
        <h3>Reset password</h3>
        <p>Enter your email to reset your password</p>
        <br />  
        <div className="vca-form-line">
          <FormControl>
            <Field
              component={RenderTextField}
              name="email"
              placeholder="Email"
              variant="outlined"
            />
          </FormControl>
        </div>

        <div className="vca-form-line">
          <Button
            className="vca-btn w-100pct"
            variant="contained"
            type="submit"
          >
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
};

const formConfig = {
  form: "FORGOT_PASSWORD_TOKEN_FORM",
  validate,
  onSubmit: (values, dispatch) => {
    const url = `${window.location.hostname}/reset-password?email=${values.email.replace('+', '%2B')}&token=`
    const request = {
      ...values,
      redirect_to: url
    }

    dispatch(getTokenForgotPassword.request(request));
    dispatch(reset("FORGOT_PASSWORD_TOKEN_FORM"));
  },
};

export default reduxForm(formConfig)(ForgotPasswordToken);
