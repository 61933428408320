import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import LoadingIndicator from "components/Loading/LoadingIndicator";
import useFetchUser from "hooks/useFetchUser";
import { useAuth } from "providers/AuthContext";

interface PrivateRoutesStrategyProps {
  component: React.ComponentType<any>;
}

const PrivateRoutesStrategy: React.FC<PrivateRoutesStrategyProps> = ({ component: Component, ...rest }) => {
  const { loading, fetchUser } = useFetchUser();
  const { user } = useAuth();

  useEffect(() => {
    if(!user){
      fetchUser()
    }
  }, [])

  if (loading) {
    return (
      <div>Trying to log in...</div>
    )
  }
  if (loading === false && !user) {
    return <Redirect to="/login" />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("vanCleef_app_token") ? (
          <>
            <Component {...props} />
            <LoadingIndicator />
          </>
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  )
};

PrivateRoutesStrategy.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoutesStrategy;
