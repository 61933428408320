import HTTPClient from "httpClient/dataClient";
import { Dto } from "models/Dto/Dto.interface";
import { UserDto } from "models/User.interface";
import { useAuth } from "providers/AuthContext";
import { useCallback, useState } from "react";

interface UseFetchUserDataResult {
  fetchUser: () => void;
  loading: boolean | undefined;
  error: Error | null;
}

const useFetchUser = (): UseFetchUserDataResult => {
  const { updateUser } = useAuth();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<Error | null>(null);

  const fetchUser = useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response: Dto<UserDto> = await HTTPClient.createClient().get(`/users/me`);
        if (response.data.user) {
          updateUser(response.data.user)
        } if ((response.data as any).error) {
          updateUser(null)
        }
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }

    };

    fetchData();
  }, [])

  return { fetchUser, loading, error };

}

export default useFetchUser;