import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "../ProductStore.utils";
import { brandsListNames } from "./BrandsListNames";

export const getBrandsListRecord = (): MultiSelectRecord => createRecordFromNames(brandsListNames);

/* const suggestedBrandsNames: Array<string> = [
  ReusedBrandsNames.Cartier,
  ReusedBrandsNames.Tiffany_Co,
  ReusedBrandsNames.Van_Cleef_Arpels,
  ReusedBrandsNames.Bulgari,
  ReusedBrandsNames.Rolex,
  ReusedBrandsNames.Patek_Philippe,
  ReusedBrandsNames.Audemars_Piguet,
];
 */

