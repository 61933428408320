
export enum DuplicataUserValues {
  KEEP_A = 0, //remove left / B
  KEEP_B = 1, // remove right / A
  SOLD_TWICE = 2,
  NOT_DUPLICATED = -1, // do not select any 
  PROBLEM = -2, // do not select any 
}

export interface Duplicatas {
  count: number
  data: Array<Array<string>>
}