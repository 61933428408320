import { defaultStrictKeywords } from "components/models/Keywords";
import { replaceQuoteWithStraightQuote, unformatStringNumber } from "helpers/text-utils";
import { getDefaultCurrency } from "utils/currency";
import { getDefaultSortBy } from "utils/filters.utils";
import { undefinedIfEmpty } from "utils/objects.utils";
import { ProductStore, ProductStoreCarat, ProductStoreDateManufacture, ProductStoreDateSale, ProductStorePrice, ProductStoreSearch } from "./ProductStore.interface";
import { mapAuctionsStore, mapClarityStore, mapColorGradeStore, mapCreationTypesStore, mapMarketplacesStore, mapStatusStore, mapCategoriesStore } from "./ProductStoreFilterCheckboxMapper";

export const getProductStoreMapper = (data: ProductStore | ProductStoreSearch | undefined): ProductStore => (
  {
    keyword: data?.keyword ? replaceQuoteWithStraightQuote(data.keyword) : undefined ?? undefined,
    search_mode: defaultStrictKeywords(data?.search_mode),
    price: getProductStorePrice(data?.price),
    stone: {
      carat: getProductStoreCarat(data?.stone?.carat),
      cut: data?.stone?.cut ?? undefined,
      colorGrade: mapColorGradeStore(data?.stone?.colorGrade),
      clarity: mapClarityStore(data?.stone?.clarity),
      stone_name: data?.stone?.stone_name ?? undefined,
      origins: data?.stone?.origins ?? undefined,
      certifications: data?.stone?.certifications ?? undefined,
      colors: data?.stone?.colors ?? undefined,
      treated: data?.stone?.treated ?? undefined,
    },
    date: getProductStoreDate(data?.date),
    date_manufacture: getProductStoreDateManufacture(data?.date_manufacture),
    status: mapStatusStore(data?.status),
    auctions: mapAuctionsStore(data?.auctions),
    marketplaces: mapMarketplacesStore(data?.marketplaces),
    categories: mapCategoriesStore(data?.categories),
    creationTypes: mapCreationTypesStore(data?.creationTypes),
    brands: data?.brands ?? undefined,
  }
)


export const getProductStoreSearchMapper = (data: ProductStoreSearch | undefined, isVanCleef?: boolean, selectedSortBy?: string): ProductStoreSearch => (
  {
    ...getProductStoreMapper(data),
    page: data?.page ?? 1,
    sort_by: data?.sort_by || selectedSortBy || getDefaultSortBy(isVanCleef),
    currency: data?.currency ?? getDefaultCurrency(isVanCleef),
  }
)

// Categories mapper
export const getProductStorePrice = (values: ProductStorePrice): ProductStorePrice => ({
  price_min: undefinedIfEmpty(unformatStringNumber(values?.price_min)),
  price_max: undefinedIfEmpty(unformatStringNumber(values?.price_max)),
})

export const getProductStoreCarat = (values: ProductStoreCarat): ProductStoreCarat => ({
  carat_min: undefinedIfEmpty(unformatStringNumber(values?.carat_min)),
  carat_max: undefinedIfEmpty(unformatStringNumber(values?.carat_max)),
})

export const getProductStoreDate = (values: ProductStoreDateSale): ProductStoreDateSale => ({
  date_min: undefinedIfEmpty(values?.date_min),
  date_max: undefinedIfEmpty(values?.date_max),
})

export const getProductStoreDateManufacture = (values: ProductStoreDateManufacture): ProductStoreDateManufacture => ({
  date_manufacture_min: undefinedIfEmpty(values?.date_manufacture_min),
  date_manufacture_max: undefinedIfEmpty(values?.date_manufacture_max),
})