import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from "store/helpers/actionsHelpers";

export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "error";
export const INFO = "info";

export const APPLICATION_IS_READY = "APPLICATION_IS_READY";
export const DATA_IS_LOADING = "DATA_IS_LOADING";
export const TOKEN_FORGOT_PASSWORD = "TOKEN_FORGOT_PASSWORD";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const showNotification = generateSimpleAction(SHOW_NOTIFICATION);
export const hideNotification = generateSimpleAction(HIDE_NOTIFICATION);

export const GET_THEME_NAME = "GET_THEME_NAME";
export const getThemeName = generateSimpleAction(GET_THEME_NAME);

export const LOGOUT = "LOGOUT";
export const logout = () => ({
  type: LOGOUT,
});

export const loadApplication = (themeName) => ({
  type: APPLICATION_IS_READY,
  isReady: true,
  themeName
});

export const isLoadingData = (prefix, isLoading) => {
  return {
    type: DATA_IS_LOADING,
    prefix,
    isLoading,
  };
};

export const emailForgotPassword = (emailForgotPassword) => {
  return {
    type: TOKEN_FORGOT_PASSWORD,
    emailForgotPassword: emailForgotPassword
  };
};

export const GET_TOKEN_PROFILE = createRequestTypes("GET_TOKEN_PROFILE");
export const getTokenProfile = generateRequestActions(GET_TOKEN_PROFILE);

export const GET_TOKEN_FORGOT_PASSWORD = createRequestTypes("GET_TOKEN_FORGOT_PASSWORD");
export const getTokenForgotPassword = generateRequestActions(GET_TOKEN_FORGOT_PASSWORD);

export const RESET_PASSWORD = createRequestTypes("RESET_PASSWORD");
export const resetPassword = generateRequestActions(RESET_PASSWORD);
