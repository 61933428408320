import { JewelResultsDto } from "models/Dto/JewelsDto.interface";

export const mapProductsRequestsHistory = (
  existingHistory: Map<string, JewelResultsDto>,
  response: JewelResultsDto
): Map<string, JewelResultsDto> => {
  // ici ne garder que la derniere requete
  // const singleHistory = new Map([[response.requestUrl, response]])
  // return singleHistory

  // si dessous si on garde tout l'historique
  // existingHistory.set(response.requestUrl, response)
  // return existingHistory


  //Ici on ne garde que les 2 derniers elements de l'historique
  existingHistory.set(response.requestUrl, response);
  if (existingHistory.size > 2) {
    const firstKey = existingHistory.keys().next().value;
    existingHistory.delete(firstKey);
  }

  return existingHistory;
}