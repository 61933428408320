import { put } from "redux-saga/effects";
import { isLoadingData } from "../application/ApplicationActions";
import { Dto } from "models/Dto/Dto.interface";
import { RequestActions } from "store/models/Store.interface";

const defaultResponseFormatter = (response: Dto<any>) => {
  if(typeof response.data === 'string'){
    return response.data
  } 
  return {
    ...response.data,
    requestUrl: response.config.url
  }
};

function* baseHandleRequest(
  requestActions: RequestActions,
  promise: Promise<Dto<any>>,
  actionParams = { triggerModalLoader: false },
  responseFormatter = defaultResponseFormatter
) {
  const { prefix } = requestActions;
  const { ...otherActionParams } = actionParams;
  yield put(isLoadingData(prefix, true));
  try {
    const response: Dto<any> = yield promise;

    yield put(
      requestActions.success(responseFormatter(response), otherActionParams)
    );

    return response;
  } catch (e) {
    yield put(requestActions.failure(e, otherActionParams));

    throw e;
  } finally {
    yield put(isLoadingData(prefix, false));
  }
}
export function* handleRequest(
  requestActions: any,
  promise: any,
  actionParams = { triggerModalLoader: true }
) {
  yield baseHandleRequest(
    requestActions,
    promise,
    actionParams,
    defaultResponseFormatter
  );
}
