import React from "react";
import { useHistory } from "react-router";
import ProductForm from "components/productForm/ProductForm";
import { useProductForm } from "components/productForm/ProductFormHook";

const ProductFormContainer = () => {
  const history = useHistory();

  const { product, productId } = useProductForm();

  const initialValues = productId && {
    title: product?.title,
    signature: product?.signature,
    lotNumber: product?.lotNumber,
    propertyFrom: product?.propertyFrom,
    priceRealized: product?.priceRealized,
    priceEstimateLow: product?.priceEstimateLow,
    priceEstimateHigh: product?.priceEstimateHigh,
    auctionHouse: product?.auctionHouse,
    sale: product?.sale,
    place: product?.place,
    date: product?.date,
    description: product?.description,
    details: product?.details,
    lotEssay: product?.lotEssay,
    individual: product?.individual,
    postLotText: product?.postLotText,
    literature: product?.literature,
    provenance: product?.provenance,
    conditionReport: product?.conditionReport,
    saleRoomNotice: product?.saleRoomNotice,
    specialNotice: product?.specialNotice,
    urlSale: product?.urlSale,
    urlLot: product?.urlLot,
    images: product?.images,
  };

  return <ProductForm history={history} initialValues={initialValues} />;
};

export default ProductFormContainer;
