import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "models/Store/ProductStore.utils";


export const getColorGradesListRecord = (): MultiSelectRecord =>
createRecordFromNames(colorGradesListNames);

const colorGradesListNames: Array<string> = [
  "Fancy Deep",
  "Fancy Dark",
  "Fancy Vivid",
  "Fancy Intense",
  "Fancy",
  "Fancy Light",
  "Light",
  "Very Light",
  "Faint",
]