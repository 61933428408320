export const addHTMLLineBreak = (text: string) => {
  return text?.replace(/\n/g, "<br />");
}

export const replaceQuoteWithStraightQuote = (stringToChange: string) => {
  let newString = stringToChange?.replace(/[\u201C\u201D\u2018\u2019\u00BB\u00AB]/g, '"');
  newString = newString?.replace(/[\u00A0]/g, '');
  return newString;
}

/**
 * Remove coma in strings
 * @param val 
 * @returns 
 */
export const unformatStringNumber = (val: string): string => {
  return val ? val.replace(/,/g, "") : undefined;
  /* if (typeof val === "string") {
  } else {
    return val ?? undefined;
  } */
};
