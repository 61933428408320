export enum STORE {
  SEARCH_FORM = "SEARCH_FORM"
}

export enum STORE_SEARCH_FORM_PARAMS {
  SORT_BY = "sort_by",
  CURRENCY = "currency",

  //stones
  CLARITY = "stone.clarity",
  COLOR_GRADE = "stone.colorGrade",
  STONE_NAME = "stone.stone_name",
  CARAT_MIN = "stone.carat.carat_min",
  CARAT_MAX = "stone.carat.carat_max",
  TREATED = "stone.treated",
  ORIGINS = "stone.origins",
  COLORS = "stone.colors",
  CUT = "stone.cut",
  CERTIFICATIONS = "stone.certifications",

  AUCTIONS = "auctions",
  MARKETPLACES = "marketplaces",
  CREATION_TYPES = "creationTypes",
  CATAGORIES = "categories",
  BRANDS = "brands",
  STATUS = "status"
}