import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "models/Store/ProductStore.utils";
import { certificationsListNames } from "./CertificationsNames";

export const getCertificationsListRecord = (): MultiSelectRecord => createRecordFromNames(certificationsListNames);

/* const suggestedCertificationsNames: Array<string> = [
  CertificationsNames.GIA,
  CertificationsNames.Gubelin,
  CertificationsNames.SSEF,
  CertificationsNames.AIGS,
  CertificationsNames.Gem_Lab
]; */