export enum CertificationsNames {
  GIA = "GIA",
  Gubelin = "Gübelin",
  SSEF = "SSEF",
  AIGS = "AIGS",
  Gem_Lab = "Gem Lab"
}

export const certificationsListNames: Array<string> = [
  'AGGL',
  'AGIL',
  'AGL',
  'AGS',
  'AGTA',
  'AGTL',
  'AIG',
  'AIGL',
  CertificationsNames.AIGS,
  'ALGT',
  'AnchorCert',
  'BGI',
  'Bellerophon',
  'Big Lab Research',
  'Bulgari Gemmological',
  'C. Dunaigre',
  'CCIP',
  'CDC',
  'CGL',
  'CGTL',
  'CISGEM',
  'Central Gem Laboratory',
  'Centro Gemologico Colombiano',
  'Centro gemologico S.A',
  'Chanthaburi Gemological Laboratory',
  'Colombian Emerald International',
  'Centro Analisi Gemmologiche',
  'DPL',
  'Danat',
  'DeGeb',
  'DSEF',
  'EDR',
  'EG',
  'EGL',
  'Emil Gem Laboratory',
  'FGL',
  'French Gemological Laboratory',
  'GAA',
  'GAL',
  'GCI',
  'GCS',
  'GEM Paris',
  'GFCO',
  'GGL',
  'GGS',
  'GGTL',
  CertificationsNames.GIA,
  'GIC',
  'GIL',
  'GIT',
  'GIS',
  'GJEA',
  'GLC',
  'GPL',
  'GRA',
  'GRC',
  'GRS',
  'GSL',
  'Global Gems Laboratory',
  'Gem & Pearl Laboratory',
  'Gem-A',
  CertificationsNames.Gem_Lab,
  'Gem Report Antwerp',
  'Gem Research Japan',
  'GemConsult',
  'Gemco',
  'Gemmological Association of All Japan',
  'Gemmological Certification Services',
  'Gemmological Certificate Services',
  'Gemmological Institute of Colombo',
  'Gemological Appraisal Laboratory of America',
  'Gemological Consultancy',
  'Gemological London Laboratory of Alexandrites',
  'Gemological Report',
  CertificationsNames.Gubelin,
  'HKGL',
  'HRD',
  'Hong Kong Jade & Stone Laboratory',
  'I.G.N',
  'ICA',
  'IGE',
  'IGI',
  'IGL',
  'IGN',
  'IGM',
  'Ignius Laboratorio Gemologico',
  'Independent Gemmologist',
  'Institut de gemmologie de Monaco',
  'Japan Gemmological Science Institute',
  'Jewellery Trade Laboratory',
  'JORGC',
  'LabiGem',
  'Laboratoire Gemmologique de Paris',
  'LFG',
  'Lotus',
  'Mason Kay',
  'MUZO',
  'NEL',
  'NGI',
  'NGTC',
  'NLG',
  'NGL',
  'Netherlands Gem Laboratory',
  'Paspaley',
  'Pearl Science Laboratory',
  'RAG',
  'S.P.Yu',
  'SGR',
  CertificationsNames.SSEF,
  'Schupf company',
  'Service Publique du Contrôle des Diamants',
  'Société Suisse de Gemmologie',
  'Stalwart Gem Laboratory',
  'The Precious Stone Laboratory London',
  'Tiffany Gemological Laboratory',
  'WGI',
  'Zam Gems Laboratory',
  'Zhong Heng Yu Testing Center of Jiangsu Province',
]