export enum StonesNames {
  DIAMOND = "diamond",
  EMERALD = "emerald",
  RUBY = "ruby",
  SAPPHIRE = "sapphire",
  AQUAMARINE = "aquamarine",
}

export const stonesListNames: Array<string> = [
  StonesNames.DIAMOND,
  StonesNames.RUBY,
  "spinel",
  "kunzite",
  StonesNames.EMERALD,
  StonesNames.SAPPHIRE,
  "agate",
  "alexandrite",
  "garnet",
  "amazonite",
  "amber",
  "amethyst",
  "ametrine",
  "ammolite",
  "andalusite",
  "andesine",
  "apatite",
  StonesNames.AQUAMARINE,
  "aragonite",
  "azurite",
  "benitoite",
  "beryl",
  "bloodstone",
  "calcite",
  "carnelian",
  "chalcedony",
  "charoite",
  "chrysoberyl",
  "chrysocolla",
  "chrysoprase",
  "cinnabar",
  "citrine",
  "colemanite",
  "coral",
  "cordierite",
  "corundum",
  "diaspore",
  "diopside",
  "dioptase",
  "epidote",
  "feldspar",
  "fluorite",
  "grandidierite",
  "heliodor",
  "hematite",
  "hessonite",
  "howlite",
  "vesuvianite",
  "iolite",
  "jadeite",
  "jasper",
  "jeremejevite",
  "kyanite",
  "labradorite",
  "lapis lazuli",
  "lazulite",
  "malachite",
  "marcasite",
  "moldavite",
  "moonstone",
  "nephrite",
  "obsidian",
  "onyx",
  "opal",
  "tourmaline",
  "pearl",
  "peridot",
  "prehnite",
  "pyrite",
  "quartz",
  "quartzite",
  "rhodochrosite",
  "rhodonite",
  "rubellite",
  "scapolite",
  "serpentine",
  "sillimanite",
  "smithsonite",
  "sodalite",
  "sphalerite",
  "sphene",
  "spodumene",
  "sugilite",
  "sunstone",
  "tanzanite",
  "thomsonite",
  "tiger's eye",
  "topaz",
  "turquoise",
  "wollastonite",
  "zircon",
]
