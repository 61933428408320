
import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "models/Store/ProductStore.utils";
import { originsListNames } from "./OriginNames";


export const getOriginsListRecord = (): MultiSelectRecord => createRecordFromNames(originsListNames);

/* const suggestedOriginsNames: Array<string> = [
  ReusedOriginsNames.AUSTRALIA,
  ReusedOriginsNames.BRAZIL,
  ReusedOriginsNames.BURMA,
  ReusedOriginsNames.CEYLON,
  ReusedOriginsNames.COLOMBIA,
]; */