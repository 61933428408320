export enum ReusedBrandsNames {
  Cartier = "Cartier",
  Tiffany_Co = "Tiffany & Co",
  Van_Cleef_Arpels = "Van Cleef & Arpels",
  Bulgari = "Bulgari",
  Rolex = "Rolex",
  Patek_Philippe = "Patek Philippe",
  Audemars_Piguet = "Audemars Piguet",
}

export const brandsListNames: Array<string> = [
  "A. Lange & Söhne",
  "Adie Brothers Ltd",
  "Adler",
  "Agassiz",
  "Alain Silberstein",
  "Alan Martin Gard",
  "Alberto Giacometti",
  "Aldo Cipullo",
  "Aletto Brothers",
  "Alexander Calder",
  "Alexander Laut",
  "Allan Adler",
  "American Watch Co",
  "Andersen Genève",
  "Andrew Clunn",
  "Andrew Grima",
  "André Derain",
  "Angela Cummings",
  "Angelus",
  "Anish Kapoor",
  "Anita Ko",
  "Anonimo",
  "Antoine Preziuso",
  "Antonini",
  "Antonio Pineda",
  "Arcadia",
  "Arlaud",
  "Arman",
  "Army & Navy",
  "Arnaldo Pomodoro",
  "Arnold & Son",
  "Arsa",
  "Artemis",
  "Arthur King",
  "Asch Grossbardt",
  "Asprey",
  "Assael",
  "Astrua",
  "Audemars Frères",
  ReusedBrandsNames.Audemars_Piguet,
  "August Hollming",
  "B. Haas Jeune",
  "Badollet",
  "Bailey",
  "Ball",
  "Baltic",
  "Bamford",
  "Barnard",
  "Barwise",
  "Baume & Mercier",
  "Bedat & Co.",
  "Bell & Ross",
  "Belperron",
  "Ben Rosenfeld",
  "Benedict",
  "Benoit De Gorski",
  "Benrus",
  "Bentley",
  "Bernard Buffet",
  "Berthoud",
  "Bertolucci",
  "Betty Cooke",
  "Beyer",
  "Bhagat",
  "Birks",
  "Black Starr & Frost",
  "Blancpain",
  "Boghossian",
  "Boodles",
  "Bordier",
  "Bottega Veneta",
  "Boucheron",
  "Bovet",
  "Breguet",
  "Breitling",
  "Bremont",
  "Brm",
  "Browns",
  "Bry",
  "Buccellati",
  "Bucherer",
  "Bueche Girod",
  "Bugatti",
  "Buhré",
  ReusedBrandsNames.Bulgari,
  "Bulova",
  "Buren",
  "Burle Marx",
  "Bvlgari",
  "C.H. Meylan",
  "Calabrese",
  "Carl F. Bucherer",
  "Carlo Giuliano",
  "Carnet",
  "Carrera Y Carrera",
  ReusedBrandsNames.Cartier,
  "Carvin French",
  "Castellani",
  "Castelli",
  "Cazzaniga",
  "Celine",
  "Cerrone",
  "Certina",
  "Cesar",
  "Chanel",
  "Chantecler",
  "Charles De Temple",
  "Charles Frodsham",
  "Charles Krypell",
  "Charles Loloma",
  "Charles Oudin",
  "Chatila",
  "Chaumet",
  "Cheapside",
  "Chevron",
  "Chiampesan",
  "Chiappe",
  "Child & Child",
  "Chimento",
  "Chloé",
  "Chopard",
  "Christian Lacroix",
  "Christoffel",
  "Christophe Claret",
  "Chrome Hearts",
  "Chronographe Suisse",
  "Chronoswiss",
  "Châtelain",
  "Ciner",
  "Claude Lalanne",
  "Claude Michel",
  "Claudia Ma",
  "Concord",
  "Consul",
  "Continental",
  "Corona",
  "Cortebert",
  "Corum",
  "Courvoisier",
  "Credor",
  "Crivelli",
  "Cropp & Farr",
  "Crump & Low",
  "Cuervo Y Sobrinos",
  "Curtis",
  "Cusi",
  "Cvstos",
  "Cyma",
  "Cédric Johner",
  "Damiani",
  "Damien Hirst",
  "Daniel Quare",
  "Daniel Roth",
  "David Morris",
  "David Thomas",
  "David Webb",
  "David Yurman",
  "De Beers",
  "De Bethune",
  "De Grisogono",
  "De Vroomen",
  "Deakin & Francis",
  "Delacour",
  "Delaneau",
  "Demner",
  "Denise Roberge",
  "Dennison",
  "Dent",
  "Dewitt",
  "Dietrich",
  "Dinh Van",
  "Dior",
  "Dodo",
  "Donald Claflin",
  "Doppio Cuore",
  "Dorrie Nossiter",
  "Doxa",
  "Drayson",
  "Dreicer & Co.",
  "Drocourt",
  "Du Bois Et Fils",
  "Dubey & Schaldenbrand",
  "Duchene Et Fils",
  "Dunand",
  "Dunhill",
  "E. Howard",
  "Ebel",
  "Eberhard",
  "Ebony",
  "Ed Wiener",
  "Edouard Juvet",
  "Edward Prior",
  "Election",
  "Elgin",
  "Elizabeth Gage",
  "Elizabeth Locke",
  "Ellicott",
  "Enicar",
  "Enigma",
  "Ephraim",
  "Ernest Guinand",
  "Erté",
  "Eska",
  "Etcetera",
  "Eterna",
  "Ettore Sottsass",
  "European Watch & Clock Co",
  "Exact",
  "Exposition Universelle",
  "F.P. Journe",
  "Fabergé",
  "Fabio Salini",
  "Faraone",
  "Fasano",
  "Favre-Leuba",
  "Federico Buccellati",
  "Fendi",
  "Ferdinand Barbedienne",
  "Ferdinand Berthoud",
  "Ferragamo",
  "Ferrari",
  "Flato",
  "Flor",
  "Fontaine",
  "Fontana",
  "Fope",
  "Forget",
  "Fortis",
  "Francis Ford",
  "Franck Muller",
  "Frank Gehry",
  "Frascarolo",
  "Fred",
  "Fred Davis",
  "Fred Leighton",
  "Fred Paris",
  "Friedrich",
  "Frères Rochat",
  "Frédérique Constant",
  "G. Petochi",
  "Gallet",
  "Garnier",
  "Garrard",
  "Gautrait",
  "Gavello",
  "Gay Frères",
  "Georg Jensen",
  "George Daniels",
  "Georges Braque",
  "Georges Fouquet",
  "Georges Lenfant",
  "Gerald Benney",
  "Gevril",
  "Gianmaria Buccellati",
  "Gide",
  "Gilbert Albert",
  "Giorgio Armani",
  "Girard Perregaux",
  "Girard-Perregaux",
  "Glashütte",
  "Glashütte Original",
  "Glycine",
  "Golay Fils & Stahl",
  "Goldpfeil",
  "Goossens",
  "Goshwara",
  "Graff",
  "Graham",
  "Grand Seiko",
  "Greubel Forsey",
  "Griffin",
  "Grima",
  "Gripoix",
  "Gros",
  "Grosse",
  "Gruen",
  "Gucci",
  "Guinand",
  "Gumps",
  "Gurhan",
  "Guy Ellia",
  "Gérald Genta",
  "Gübelin",
  "H. Moser & Cie.",
  "H. R. Ekegren",
  "H. Stern",
  "Hamilton",
  "Hamilton & Co",
  "Hammerman Brothers",
  "Hancocks",
  "Hanhart",
  "Hans Hansen",
  "Harald Nielsen",
  "Hardy Bros",
  "Harry Bertoia",
  "Harry Winston",
  "Harwood",
  "Hautlence",
  "Hector Aguilar",
  "Helvetia",
  "Hemmerle",
  "Hennell",
  "Henri Vever",
  "Henrik Wigström",
  "Henry Capt",
  "Henry Dunay",
  "Hermès",
  "Hervé Van Der Straeten",
  "Hirsch",
  "Hoffman",
  "Horus",
  "Huber",
  "Hubert",
  "Hublot",
  "Huguenin",
  "Hyt",
  "IWC",
  "Ikepod",
  "Ilbery",
  "Illario",
  "Illinois",
  "Imhof",
  "Invicta",
  "Ippolita",
  "Isaac Lowndes",
  "J. Assmann",
  "J. E. Caldwell & Co.",
  "J. F. Bautte & Cie",
  "J.W. Benson",
  "Jabel",
  "Jacob & Co.",
  "Jacobus",
  "Jacques Timey",
  "Jaeger-Lecoultre",
  "Jahan",
  "James Cox",
  "Janesich",
  "Japy Freres",
  "Jaquet Droz",
  "Jar",
  "Jean Cocteau",
  "Jean Despres",
  "Jean Dunand",
  "Jean Fouquet",
  "Jean Mahie",
  "Jean Richard",
  "Jean Vendome",
  "Jean Vitau",
  "Jean-Mairet Gillman",
  "Jefferson",
  "Joan Collins",
  "Johan Rohde",
  "John Arnold",
  "John Bennett",
  "John Donald",
  "John Ellicott",
  "John Forrest",
  "John Hardy",
  "John Rubel",
  "Jorg Hysek",
  "Jose Hess",
  "Joseph Knibb",
  "Judith Leiber",
  "Judith Ripka",
  "Jules Jürgensen",
  "Julius Cohen",
  "Junghans",
  "Justin Vulliamy",
  "Juvenia",
  "Karl Griesbaum",
  "Kat Florence",
  "Kern",
  "Kieselstein-Cord",
  "Koch",
  "Koenig",
  "Kurono",
  "Kurt Wayne",
  "Kutchinsky",
  "Kwiat",
  "Köchert",
  "L'Epée",
  "L.E.",
  "La Triomphe",
  "Lacloche",
  "Laco",
  "Lalaounis",
  "Lalique",
  "Lami",
  "Lanvin",
  "Lapponia",
  "Larry",
  "Laughing Buddha",
  "Laurent Ferrier",
  "Le Bon",
  "Le Phare",
  "Le Roy",
  "Lemania",
  "Leo Pizzo",
  "Leonidas",
  "Lepaute",
  "Lepine",
  "Leysen",
  "Liberty",
  "Line Vautrin",
  "Lip",
  "Lombard",
  "Longines",
  "Lord Elgin",
  "Lorenz Bäumer",
  "Lorraine Schwartz",
  "Los Castillo",
  "Louis Audemars",
  "Louis Comfort Tiffany",
  "Louis Erard",
  "Louis Moinet",
  "Louis Vuitton",
  "Louise Bourgeois",
  "Louise Nevelson",
  "Lucien Gautrait",
  "Lucien Piccard",
  "Lucio Fontana",
  "Ludovic Ballouard",
  "Lugano",
  "Luna Felix",
  "Luxor",
  "Lydia Courteille",
  "Léon Hatot",
  "M. Gérard",
  "MB&F",
  "Macchiarini",
  "Magellan",
  "Man Ray",
  "Mappin & Webb",
  "Marc Nicolet",
  "Marchak",
  "Marco Bicego",
  "Marcus & Co.",
  "Margaine",
  "Margherita Burgener",
  "Marianne Ostier",
  "Marina B.",
  "Mario Buccellati",
  "Marquis",
  "Martine",
  "Martinelli",
  "Marvin",
  "Marzo",
  "Masriera",
  "Mathey-Tissot",
  "Mauboussin",
  "Maurice Lacroix",
  "Max Ernst",
  "Maz",
  "Meister",
  "Mellerio",
  "Messika",
  "Meyer",
  "Michael Youssoufian",
  "Michele Della Valle",
  "Michele della Valle",
  "Mido",
  "Miki",
  "Mikimoto",
  "Milner",
  "Minerva",
  "Ming",
  "Missiaglia",
  "Modern",
  "Moeris",
  "Montblanc",
  "Montega",
  "Moroni",
  "Mouawad",
  "Moulinié",
  "Moussaieff",
  "Movado",
  "Mugnier",
  "Nardi",
  "National Watch Co",
  "Neiman Marcus",
  "Niton",
  "Nivada",
  "Nomos Glashütte",
  "Norma",
  "O.J. Perrin",
  "Omega",
  "Orient",
  "Oris",
  "Oscar Heyman & Brothers",
  "Oudin",
  "Pablo",
  "Paloma Picasso",
  "Panerai",
  "Paolo Costagli",
  "Para",
  "Parkinson & Frodsham",
  "Parmigiani Fleurier",
  "Paspaley",
  "Pasquale Bruni",
  ReusedBrandsNames.Patek_Philippe,
  "Patron",
  "Paul Buhré",
  "Paul Ditisheim",
  "Paul Flato",
  "Paul Garnier",
  "Paul Picot",
  "Pederzani",
  "Percossi Papi",
  "Perrelet",
  "Perret",
  "Pesavento",
  "Peter Macchiarini",
  "Petochi",
  "Peugeot",
  "Philippe Dufour",
  "Phillips",
  "Piaget",
  "Picard",
  "Picchiotti",
  "Pierce",
  "Pierre Balmain",
  "Pierre Cardin",
  "Pierre Kunz",
  "Players",
  "Plojoux",
  "Poiray",
  "Pol Bury",
  "Pomellato",
  "Porsche Design",
  "Prada",
  "Quad",
  "Rado",
  "Ralph Lauren",
  "Ram",
  "Raymond",
  "Raymond Templier",
  "Raymond Yard",
  "Record",
  "Recordon",
  "René Boivin",
  "Repossi",
  "Ressence",
  "Reuge",
  "Reutter",
  "Revue Thommen",
  "Rey Urban",
  "Reza",
  "Richard Mille",
  "Rigaud",
  "Ritz",
  "Roamer",
  "Robert Roskell",
  "Roberto Cavalli",
  "Roberto Coin",
  "Roberts",
  "Robin",
  "Rockford",
  "Roger Dubuis",
  ReusedBrandsNames.Rolex,
  "Rolls-Royce",
  "Romain Jerome",
  "Romilly",
  "Ron Arad",
  "Rossi",
  "Rotary",
  "Rotherhams",
  "Roux",
  "Ruser",
  "Rutherford",
  "Sabbadini",
  "Salavetti",
  "Salvador Dali",
  "Sandoz",
  "Sanz",
  "Sarcar",
  "Sarpaneva",
  "Saunders",
  "Scavia",
  "Schlumberger",
  "Schulz",
  "Schwarz Etienne",
  "Seaman Schepps",
  "Seiko",
  "Sellier",
  "Shaun Leane",
  "Shilling",
  "Shreve & Co",
  "Sinn",
  "Smith & Son",
  "Smiths",
  "Solange Azagury-Partridge",
  "Sonia Delaunay",
  "Sophia D",
  "Speake-Marin",
  "Spink",
  "Starr & Frost",
  "Stefan Hafner",
  "Stephen Webster",
  "Sterlé",
  "Steuben",
  "Suzanne Belperron",
  "Suzanne Kalan",
  "Swatch",
  "Sydney Evan",
  "Tabbah",
  "Tacori",
  "Taffin",
  "Tag Heuer",
  "Talbot",
  "Tamara Comolli",
  "Tane",
  "Tapio Wirkkala",
  "Tavannes",
  "Taxi",
  "Technomarine",
  "Temple St. Clair",
  "Tessiers",
  "Theo Fennell",
  "Theodoros",
  "Thomas Earnshaw",
  "Thomas Mudge",
  "Thomas Russell & Son",
  "Thomas Tompion",
  ReusedBrandsNames.Tiffany_Co,
  "Tiffany & Co.",
  "Tiffany Studios",
  "Timor",
  "Tissot",
  "Titus",
  "Tony White",
  "Touchon & Co.",
  "Tourneau",
  "Trabert & Hoeffer",
  "Trajan",
  "Trianon",
  "Trio",
  "Tudor",
  "Türler",
  "U-Boat",
  "Ulysse Nardin",
  "Unbranded",
  "Ungaro",
  "Union",
  "Universal Geneve",
  "Universal Genève",
  "Universal, Geneve",
  "Universal, Genève",
  "Unoaerre",
  "Urban Jürgensen",
  "Urwerk",
  "Uti",
  "Utopia",
  "Vacheron Constantin",
  "Valentin Magro",
  "Valentino",
  ReusedBrandsNames.Van_Cleef_Arpels,
  "Van Der Bauwede",
  "Van Der Klaauw",
  "Vasari",
  "Vauchez",
  "Ventrella",
  "Ventura",
  "Verdura",
  "Verger Frères",
  "Verney",
  "Versace",
  "Vever",
  "Vhernier",
  "Vianney Halter",
  "Villa",
  "Viner",
  "Vita",
  "Vourakis",
  "Voutilainen",
  "Vulcain",
  "Vulliamy",
  "Waldan International",
  "Wallace Chan",
  "Walt Disney Productions",
  "Waltham",
  "Wander",
  "Webster",
  "Weingrill",
  "Wempe",
  "Wiese",
  "William Comyns",
  "William Dutton",
  "William Goldberg",
  "William Marshall",
  "William Spratling",
  "Wittnauer",
  "Wolfers",
  "Wyler",
  "Yema",
  "Yves Saint Laurent",
  "Zadora",
  "Zenith",
  "Zodiac",
  "Zolotas"
]