import { ConnectedRouter } from "connected-react-router";
import ForgotPassword from "pages/ForgotPassword";
import LoginPage from "pages/LoginPage";
import ResetPassword from "pages/ResetPassword";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoutesStrategy from "routes/PrivateRoutesStrategy";
import RoutesPrivate from "routes/RoutesPrivate";
import { history } from "store/store";
import ScrollToTop from "./components/shared/scroll-to-top/scroll-to-top";
import { themeNameSelector } from "./store/application/ApplicationSelectors";
import './theme/scss/app.scss';
import TermOfUse from "pages/TermOfUse";
import { isVanfCleefProcess } from "helpers/constants";


const App: React.FC = () => {
  const themeName = useSelector(themeNameSelector);

  const isVanCleef = isVanfCleefProcess();
  useEffect(() => {
    document.title = getDocumentTitle(themeName);

  }, [])


  const getDocumentTitle = (theme: string) => {
    switch (theme) {
      case "van-cleef":
      case "vancleefdbluxe":
        return "Van Cleef & Arpels";
      default:
        return "Jewels For Eternity";
    }
  };

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <Switch>
        <Route exact path={"/"}>
          <Redirect to={"/category"} />
        </Route>
        <Route
          exact
          path={"/login"}
          render={() => <LoginPage />}
        />
        <Route
          exact
          path={"/forgot-password"}
          render={() => <ForgotPassword />}
        />
        <Route
          exact
          path={"/reset-password"}
          render={() => <ResetPassword />}
        />
        {!isVanCleef && (
        <Route
          exact
          path={"/terms"}
          render={() => <TermOfUse />}
        />
        )}
        <PrivateRoutesStrategy component={RoutesPrivate} />
      </Switch>
    </ConnectedRouter>
  )
};

export default App;



