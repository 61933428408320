import React from 'react';

const TermOfUse: React.FC = () => {
  return (
    <div style={{ width: '100%', height: '100vh', border: 'none', overflow: 'hidden' }}>
      <iframe
        src="https://drive.google.com/file/d/1crgQpHA7zMchZ33nEt-E6xbBF2JHDDND/preview"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="PDF Viewer"
      />
    </div>
  );
};

export default TermOfUse;
