import { ZoomIn, ZoomOut } from "@material-ui/icons";
import { useZoomImageWheel } from "@zoom-image/react";
import React, { useEffect, useRef } from "react";
import './zoom-image.scss';

interface ZoomImageProps {
  imgSrc: string;
}

const ZoomImage: React.FC<ZoomImageProps> = ({
  imgSrc
}) => {

  const imageWheelContainerRef = useRef<HTMLDivElement>(null);
  const {
    createZoomImage: createZoomImageWheel,
    zoomImageState: zoomImageWheelState,
    setZoomImageState: setZoomImageWheelState,
  } = useZoomImageWheel();

  useEffect(() => {
    const imageContainer = imageWheelContainerRef.current as HTMLDivElement
    if (imageContainer) {
      createZoomImageWheel(imageContainer)
    }
  }, [])


  const zoomInWheel = () => {
    setZoomImageWheelState({
      currentZoom: zoomImageWheelState.currentZoom + 0.5,
    });
  }

  const zoomOutWheel = () => {
    setZoomImageWheelState({
      currentZoom: zoomImageWheelState.currentZoom - 0.5,
    });
  }

  return (
    <div className="zoom-image-carousel">
      <div className="imageContainer" ref={imageWheelContainerRef}>
        <img className="image" alt="Image " src={imgSrc} />
      </div>
      <div className="zoom-buttons">
      <button
          onClick={zoomOutWheel}
          className="vca-btn vca-btn--secondary"
        >
          <ZoomOut />
        </button>
        <button
          onClick={zoomInWheel}
          className="vca-btn vca-btn--secondary"
        >
          <ZoomIn />
        </button>
      </div>
    </div>
  )
}

export default ZoomImage;