export enum AuctionsNames {
  Antiquorum = "Antiquorum",
}

export const auctionsListNames: Array<string> = [
  "Christie's",
  "Sotheby's",
  "Bonhams",
  "Phillips",
  "Doyle",
  "Artcurial",
  "Tajan",
  AuctionsNames.Antiquorum,
]