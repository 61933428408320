import { addHTMLLineBreak } from 'helpers/text-utils';
import { JewelDto } from 'models/Dto/JewelsDto.interface';
import { getStatusLabel } from 'utils/jewels.utils';
import { awsRepoImg } from '../../helpers/constants';

export interface InfoProduct {
  label: string;
  text?: string;
  isLong: boolean;
  isUrl?: boolean;
}

class ViewProductModel {

  private _hasImage: boolean;
  private _imageNumber: number;
  private product: JewelDto | null;

  constructor(product: any) {
    this.product = product;
    this._hasImage = false;
    this._imageNumber = 0;
  }

  get hasImage() {
    return this._hasImage;
  }

  get imageNumber() {
    return this._imageNumber;
  }

  get displayedDate() {
    const startDate = this.product.starting_date ? new Date(this.product?.starting_date).toLocaleString('en-US', ({ year: 'numeric', month: 'long', day: 'numeric' })) : '-';
    const endDate = this.product?.ending_date ? new Date(this.product?.ending_date).toLocaleString('en-US', ({ year: 'numeric', month: 'long', day: 'numeric' })) : '-';

    if (startDate === endDate) {
      return `${startDate}`;
    }
    return `${startDate} - ${endDate}`;
  }

  createCarouselData() {
    if (this.product?.image?.length > 0 && this.product?.cluster_hash) {
      this._hasImage = true;
      this._imageNumber = this.product.image.length;
      const carouselData = this.product.image.map((image) => {
        return { image: `${awsRepoImg(this.product.cluster_hash, image.path)}` }
      })
      return carouselData;
    } else {
      this._hasImage = false
    }
  }

  createInfoProductList(): Array<InfoProduct> {
    if (!this.product) {
      return;
    }
    const infoProduct: Array<InfoProduct> = [
      {
        label: 'Lot Number',
        text: this.product.lot_number,
        isLong: false
      },
      {
        label: 'Type of sale',
        text: this.product.source_type,
        isLong: false
      },
      {
        label: 'Status',
        text: getStatusLabel(this.product),
        isLong: false
      },
      {
        label: 'Individual',
        text: `${this.product.serial_number}`,
        isLong: false
      },
      {
        label: 'Date Of Manufacture',
        text: `${this.product.date_manufacture_min} - ${this.product.date_manufacture_max}`,
        isLong: false
      },
      {
        label: 'Property From',
        text: addHTMLLineBreak(this.product.pre_lot_text),
        isLong: true
      },
      {
        label: 'Description',
        text: addHTMLLineBreak(this.product.lot_description),
        isLong: true
      },
      {
        label: 'Lot Essay',
        text: addHTMLLineBreak(this.product.lot_essay),
        isLong: true
      },
      {
        label: 'Post Lot Text',
        text: addHTMLLineBreak(this.product.post_lot_text),
        isLong: true
      },
      {
        label: 'Literature',
        text: addHTMLLineBreak(this.product.literature),
        isLong: true
      },
      {
        label: 'Provenance',
        text: addHTMLLineBreak(this.product.provenance),
        isLong: true
      },
      {
        label: 'Condition Report',
        text: addHTMLLineBreak(this.product.condition_report),
        isLong: true
      },
      {
        label: 'Stones',
        //text: addHTMLLineBreak(this.product.condition_report),
        isLong: true
      },
      {
        label: 'Sale Room Notice',
        text: addHTMLLineBreak(this.product.sale_room_notice),
        isLong: true
      },
      {
        label: 'Special Notice',
        text: addHTMLLineBreak(this.product.special_notice),
        isLong: true
      },
      {
        label: 'Exhibited',
        text: addHTMLLineBreak(this.product.exhibited),
        isLong: true
      },
      {
        label: 'Notes',
        text: null,
        isLong: true
      },
      {
        label: 'Auction History',
        text: null,
        isLong: true
      },
      {
        label: 'URL Sale',
        text: this.product.url_sale,
        isLong: true,
        isUrl: true
      },
      {
        label: 'URL Lot',
        text: this.product.url_lot,
        isLong: true,
        isUrl: true
      }
    ]

    return infoProduct;
  }

  mapPostprocessingStone() {
    const stonesArray = this.product?.stones;

    try {
      const toArrayIfNot = (v: any) => v ? (Array.isArray(v) ? v : [v]) : []

      const mapFinal = stonesArray?.map((s: any) => ({
        stone: s.stone,
        carat: s.carat,
        jewels: s.jewels?.join(', '),
        measures: toArrayIfNot(s.measures),
        color: s.colour,
        color_grade: s.color_grade,
        cuts: s.cuts?.join(', '),
        clarity: s.clarity?.value,
        reports: s.reports,
        origin: s.origin?.value,
        treatment: toArrayIfNot(s.treatment),
        number: s.number,
      }));

      return mapFinal;

    } catch (error) {
      console.log(error)
      return null;
    }

  }

}



export default ViewProductModel;