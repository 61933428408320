import { UserPermissions } from "models/User.interface";
import React, { createContext, useContext, useState } from "react";

interface AuthContextProps {
  user: User | null;
  updateUser: (userData: User | null) => void;
  isAdmin: () => boolean;
  hasPermissions: (permissions: Array<UserPermissions>) => boolean;
  //isAuthenticated: boolean;
  //login: (userData: User) => void;
  //logout: () => void;
}

interface User {
  email: string;
  id: string;
  permissions: string[];
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const updateUser = (userData: User | null) => {
    setIsAuthenticated(!!userData);
    setUser(userData);
    // TODO logout = setUser(null);
  }

  const isAdmin = () => (isAuthenticated && user?.permissions?.includes(UserPermissions.ADMIN)) ?? false;

  const hasPermissions = (permissions: Array<UserPermissions>): boolean =>
      (user?.permissions && permissions.every(value => user?.permissions.indexOf(value) !== -1)) ?? false



  return (
    <AuthContext.Provider value={{ user, updateUser, isAdmin, hasPermissions }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};