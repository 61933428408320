import DateFnsUtils from "@date-io/date-fns";
import { FormControl } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import RenderSelectDatePicker from "components/shared/form/RenderSelectDatePicker";
import React, { useState } from "react";
import { Field } from "redux-form";
import FilterWrapper, { FilterWrapperBorderProps } from "./shared/FilterWrapper";
import { useSelector } from "react-redux";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import { ProductStoreSearch } from "models/Store/ProductStore.interface";

const FilterDateOfSale: React.FC<FilterWrapperBorderProps> = ({
  borderBottom,
}) => {
  const searchFormData: ProductStoreSearch = useSelector(searchFormDataSelector);
  const minDate = new Date(1980, 0, 1);
  const [selectedDateFirst, handleDateFirstChange] = useState<Date>(searchFormData.date.date_min);
  const [selectedDateLast, handleDateLastChange] = useState(searchFormData.date.date_min || undefined);
  return (
    <FilterWrapper borderBottom={borderBottom}>
      <h3 className="heading-3">Date of sale</h3>
      <div className="search-panel__double-row">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="vca-textfield">
            <div className="vca-textfield__field is-datepicker-component">
              <FormControl>
                <Field
                  component={RenderSelectDatePicker}
                  name="date.date_min"
                  variant="inline"
                  minDate={minDate}
                  maxDate={selectedDateLast}
                  onChange={(date: any) => handleDateFirstChange(date)}
                />
              </FormControl>
            </div>
          </div>
          <div className="vca-textfield">
            <div className="vca-textfield__field is-datepicker-component">
              <FormControl>
                <Field
                  component={RenderSelectDatePicker}
                  name="date.date_max"
                  variant="inline"
                  minDate={selectedDateFirst}
                  onChange={(date: any) => handleDateLastChange(date)}
                />
              </FormControl>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </FilterWrapper>
  )
}

export default FilterDateOfSale;