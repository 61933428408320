import { useSelector } from "react-redux";
import {
  isNotifiedSelector,
  notificationMessageSelector,
  messageTypeSelector,
} from "store/application/ApplicationSelectors";

export const useNotificationValues = () => {
  const isNotified = useSelector(isNotifiedSelector);
  const notificationMessage = useSelector(notificationMessageSelector);
  const messageType = useSelector(messageTypeSelector);

  return {
    isNotified,
    notificationMessage,
    messageType,
  };
};
