import { SelectAllCheckboxProps } from "models/Forms/Forms.interface";
import { useCallback, useEffect, useState } from "react";

interface UseSelectAllCheckboxProps {
  checkboxState: SelectAllCheckboxProps;
}

export const useSelectAllCheckbox = ({ checkboxState }: UseSelectAllCheckboxProps) => {
  const [allChecked, setAllChecked] = useState<SelectAllCheckboxProps>({
    isIndeterminate: false,
    isSelected: false,
  });

  const [prevCheckboxState, setPrevCheckboxState] = useState<SelectAllCheckboxProps | null>(null);

  const isSameNewPrevious = (prevCheck: SelectAllCheckboxProps, newCheck: SelectAllCheckboxProps) => prevCheck.isIndeterminate === newCheck.isIndeterminate && prevCheck.isSelected === newCheck.isSelected

  useEffect(() => {
    if (prevCheckboxState !== null && !isSameNewPrevious(prevCheckboxState, checkboxState)) {
      setAllChecked({
        isIndeterminate: checkboxState.isIndeterminate,
        isSelected: checkboxState.isSelected,
      });
    }
    setPrevCheckboxState(checkboxState);
  }, [
    checkboxState,
    prevCheckboxState
  ]);

  const onCheckAll = useCallback((checkAll: boolean) => {
    setAllChecked({
      isIndeterminate: false,
      isSelected: checkAll,
    })
  }, [])

  return {
    allChecked, onCheckAll
  }
}