import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import React from "react";
import { mainCurrencies } from "utils/currency";
import { useMainCurrencyButton } from "./useMainCurrencyButtonHooks";

interface MainCurrencyButtonProps {
  requestProductList?: boolean
}

const MainCurrencyButton: React.FC<MainCurrencyButtonProps> = ({requestProductList}) => {

  const {
    currency,
    searchFormData,
    handleMainCurrency
  } = useMainCurrencyButton();

  return (
    <div className="main-currency-btn">
      <div className="title"><span className="title-main">Main</span> Currency</div>
      <div className="main-currency-btn__select">
        <div className="main-currency-btn__select__icon">
          <UnfoldMoreIcon />
        </div>
        <Select
          value={
            searchFormData.currency ? searchFormData.currency : currency
          }
          onChange={(event: any) => handleMainCurrency(event.target.value, requestProductList)}
          displayEmpty
          className=""
          inputProps={{ "aria-label": "Currency" }}
        >
          {Object.values(mainCurrencies).map((currency) => (
            <MenuItem value={currency.value} key={currency.value}>
              {currency.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}


export default MainCurrencyButton;