import { MultiSelectRecord, FilterCheckboxListProps } from "models/Filters.interface";

export const getStatusListRecord = (): MultiSelectRecord => statusList

enum StatusParams {
  SOLD = "sold",
  NOTSOLD = "notsold",
  FORSALE = "forsale"
}

const statusList: Record<StatusParams, FilterCheckboxListProps> = {
  [StatusParams.SOLD]: {
    label: "Sold",
    paramName: StatusParams.SOLD,
    dtoValue: "1"
  },
  [StatusParams.NOTSOLD]: {
    label: "Not Sold",
    paramName: StatusParams.NOTSOLD,
    dtoValue: "0"
  },
  [StatusParams.FORSALE]: {
    label: "For Sale",
    paramName: StatusParams.FORSALE,
    dtoValue: "2"
  }
};
