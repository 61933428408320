import { useRouteMatch } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductInfo } from "../../store/product/ProductActions";
import { productInfoSelector } from "../../store/product/ProductSelectors";
import HTTPClient from "../../httpClient/dataClient";
import { URLS_PARAMS_API } from "httpClient/urls.enum";
import { JewelResultsDto } from "models/Dto/JewelsDto.interface";

export const useViewProduct = () => {
  const dispatch = useDispatch();

  const { params } = useRouteMatch<{ productId: string }>();
  const { productId } = params;

  const productInfo: JewelResultsDto = useSelector(productInfoSelector);

  useEffect(() => {
    dispatch(getProductInfo.request({ productId }));
  }, [dispatch, productId]);

  const editIndividualField = (individualField: any) => {
    return new Promise((resolve) => {
      HTTPClient.createClient().put(`/${URLS_PARAMS_API.JEWLRY_LIST}/${productId}`, {
          ...individualField
      }).then((response: any) => {
          // handle success
          console.log(response)
          dispatch(getProductInfo.request({ productId }));
          resolve(response);
        })
        .catch(function (error: any) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    })
  }

  return {
    productInfo,
    editIndividualField
  };
};
