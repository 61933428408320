import HTTPClient from "httpClient/dataClient";
import { Dto } from "models/Dto/Dto.interface";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SUCCESS, showNotification } from "store/application/ApplicationActions";
import { DuplicataUserValues } from "./Duplicata.interfaces";

interface UsePostDuplicatesProps {
  userValue: DuplicataUserValues;
  duplicateTuple: Array<string>;
}
interface UsePostDuplicatesResult {
  postDuplicates: (param: UsePostDuplicatesProps) => Promise<any>;
  loading: boolean | undefined;
}

const usePostDuplicates = (): UsePostDuplicatesResult => {
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch();


  const handleShowNotification = (type: string, message: string) =>
    dispatch(showNotification({
      payload: {
        messageType: type,
        message: message,
      },
    }));


  const postDuplicates = (
    {
      userValue,
      duplicateTuple
    }: UsePostDuplicatesProps
  ) => {
    setLoading(true)
    return new Promise((resolve) => {
      HTTPClient.createClient().post("/duplicates/update", {
        "user_value": userValue,
        "duplicate_tuple": duplicateTuple
      })
        .then((response: Dto<{ message: string }>) => {
          handleShowNotification(SUCCESS, response?.data?.message)
          resolve(response);
        })
        .catch((error: any) => {
          console.error(error);
          resolve(error);
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  return { postDuplicates, loading };

}

export default usePostDuplicates;