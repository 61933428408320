import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import RenderAutoComplete from "components/shared/form/RenderAutoComplete";
import RenderCheckbox from "components/shared/form/RenderCheckbox";
import RenderNumberField from "components/shared/form/RenderNumberField";
import { SelectAllCheckboxProps } from "models/Forms/Forms.interface";
import { getCertificationsListRecord } from "models/Store/Jewels/Stones/CertificationsList";
import { getClarityListRecord } from "models/Store/Jewels/Stones/ClarityList";
import { getColorGradesListRecord } from "models/Store/Jewels/Stones/ColorGradeList";
import { getColorsListRecord } from "models/Store/Jewels/Stones/ColorsList";
import { getCutListRecord } from "models/Store/Jewels/Stones/CutList";
import { getOriginsListRecord } from "models/Store/Jewels/Stones/OriginList";
import { ProductStoreSearch } from "models/Store/ProductStore.interface";
import { mapClarityStore, mapColorGradeStore } from "models/Store/ProductStoreFilterCheckboxMapper";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change } from "redux-form";
import { STORE, STORE_SEARCH_FORM_PARAMS } from "store/models/Store.enum";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import AccordionWrapper from "../shared/AccordionWrapper";
import FilterCheckbox from "../shared/FilterCheckbox/FilterCheckbox";
import FilterWrapper, { FilterWrapperBorderProps } from "../shared/FilterWrapper";
import { getStonesListRecord } from "models/Store/Jewels/Stones/StonesList";
import { StonesNames } from "models/Store/Jewels/Stones/StonesNames";

const isFieldDisabled = (selectedStone: string | undefined): boolean => selectedStone === null || selectedStone === undefined || selectedStone === "";

interface FilterStonesProps extends FilterWrapperBorderProps {
  checkboxesColorGradeStateChanges: SelectAllCheckboxProps;
  checkboxesClarityStateChanges: SelectAllCheckboxProps;
}

const FilterStones: React.FC<FilterStonesProps> = ({
  borderBottom,
  checkboxesColorGradeStateChanges,
  checkboxesClarityStateChanges
}) => {
  const searchFormData: ProductStoreSearch = useSelector(searchFormDataSelector);
  const [selectedStone, setSelectedStone] = useState<string>(searchFormData.stone?.stone_name);
  const dispatch = useDispatch();

  const isSelectedStoneInList = (selectedStone: string, stonesList: Array<string>): boolean =>
    stonesList.includes(selectedStone)

  const onChangeStone = (stone: string) => {
    setSelectedStone(stone);
    dispatch(change(STORE.SEARCH_FORM, STORE_SEARCH_FORM_PARAMS.COLORS, undefined))
    dispatch(change(STORE.SEARCH_FORM, STORE_SEARCH_FORM_PARAMS.COLOR_GRADE, mapColorGradeStore(searchFormData.stone.colorGrade, false)))
    dispatch(change(STORE.SEARCH_FORM, STORE_SEARCH_FORM_PARAMS.CLARITY, mapClarityStore(searchFormData.stone.clarity, false)))
  }

  return (
    <FilterWrapper borderBottom={borderBottom} isSubFilter>
      <AccordionWrapper title="Stones">
        <div className="search-panel__stone-row">
          <FormControl>
            <Field
              component={RenderAutoComplete}
              name={STORE_SEARCH_FORM_PARAMS.STONE_NAME}
              placeholder="Stone"
              variant="outlined"
              recordList={getStonesListRecord()}
              onChange={(event, stone: string) => {
                onChangeStone(stone);
              }}
            />
          </FormControl>
          <FormControl>
            <Field
              component={RenderNumberField}
              name={STORE_SEARCH_FORM_PARAMS.CARAT_MIN}
              placeholder="Min"
              customInput={TextField}
              variant="outlined"
              disabled={isFieldDisabled(selectedStone)}
              classTextfield="input-with-adornment-xsmall-plr"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {" cts"}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl>
            <Field
              component={RenderNumberField}
              name={STORE_SEARCH_FORM_PARAMS.CARAT_MAX}
              placeholder="Max"
              customInput={TextField}
              variant="outlined"
              disabled={isFieldDisabled(selectedStone)}
              classTextfield="input-with-adornment-xsmall-plr"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {" cts"}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
        <div className="search-panel__treated">
          <FormControl>
            <Field
              component={RenderCheckbox}
              name={STORE_SEARCH_FORM_PARAMS.TREATED}
              label="No treatment only"
              isDisabled={isFieldDisabled(selectedStone)}
              labelPlacement="end"
            />
          </FormControl>
        </div>

        {/* Origin */}
        <div className="search-panel__item search-panel__sub-item has-border-bottom">
          <FormControl>
            <Field
              component={RenderAutoComplete}
              multiple
              name={STORE_SEARCH_FORM_PARAMS.ORIGINS}
              placeholder="Origins"
              variant="outlined"
              disabled={isFieldDisabled(selectedStone)}
              recordList={getOriginsListRecord()}
            />
          </FormControl>
        </div>

        {/* Color */}
        {isSelectedStoneInList(selectedStone, [StonesNames.DIAMOND, StonesNames.SAPPHIRE]) && (
          <div className="search-panel__item search-panel__sub-item has-border-bottom">
            <FormControl>
              <Field
                component={RenderAutoComplete}
                multiple
                name={STORE_SEARCH_FORM_PARAMS.COLORS}
                placeholder="Colors"
                variant="outlined"
                disabled={isFieldDisabled(selectedStone)}
                recordList={getColorsListRecord()}
              />
            </FormControl>
          </div>
        )}

        {/* Color Grade */}
        {/* Hide temporarly */}
        {false && isSelectedStoneInList(selectedStone, [StonesNames.DIAMOND]) && (
          <div className="search-panel__sub-item-checkbox">
            <FilterCheckbox
              borderBottom
              title="Color Grade"
              checkboxList={getColorGradesListRecord()}
              storeValuePrefix={STORE_SEARCH_FORM_PARAMS.COLOR_GRADE}
              selectAll
              isDisabled={isFieldDisabled(selectedStone)}
              storeFormName={STORE.SEARCH_FORM}
              checkboxesStateChanges={checkboxesColorGradeStateChanges}
            />

          </div>
        )}


        {/* Clarity */}
        {isSelectedStoneInList(selectedStone, [StonesNames.DIAMOND]) && (
          < div className="search-panel__sub-item-checkbox">
            <FilterCheckbox
              borderBottom
              title="Clarity"
              checkboxList={getClarityListRecord()}
              storeValuePrefix={STORE_SEARCH_FORM_PARAMS.CLARITY}
              selectAll
              isDisabled={isFieldDisabled(selectedStone)}
              storeFormName={STORE.SEARCH_FORM}
              checkboxesStateChanges={checkboxesClarityStateChanges}
            />
          </div>
        )}

        {/* Cut / Shape */}
        {/* Hide temporarly */}
        { false && (<div className="search-panel__item search-panel__sub-item has-border-bottom">
            <FormControl>
              <Field
                component={RenderAutoComplete}
                multiple
                name={STORE_SEARCH_FORM_PARAMS.CUT}
                placeholder="Shape / cut"
                variant="outlined"
                disabled={isFieldDisabled(selectedStone)}
                recordList={getCutListRecord()}
              />
            </FormControl>
          </div>)
        }

        {/* Certifications */}
        <div className="search-panel__item search-panel__sub-item mb-0">
          <FormControl>
            <Field
              component={RenderAutoComplete}
              multiple
              name={STORE_SEARCH_FORM_PARAMS.CERTIFICATIONS}
              placeholder="Certifications"
              variant="outlined"
              disabled={isFieldDisabled(selectedStone)}
              recordList={getCertificationsListRecord()}
            />
          </FormControl>
        </div>

      </AccordionWrapper>
    </FilterWrapper >
  )
}

export default FilterStones;