import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { AuthProvider } from "providers/AuthContext";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { loadApplication } from "./store/application/ApplicationActions";
import sagas from "./store/sagas";
import configureStore from "./store/store";
import styleTheme from "./theme/default";

const store = configureStore();
const theme = createTheme(styleTheme);
let classThemeName = "jewelsforeternity"; // Default value
let themeName = "jewelsforeternity"; // Default value

if (process.env.REACT_APP_THEME) {
  if (process.env.REACT_APP_THEME === "van-cleef" || process.env.REACT_APP_THEME === "vancleefdbluxe") {
    themeName = process.env.REACT_APP_THEME;
    classThemeName = "van-cleef";
  }
}

store.runSaga(sagas);
store.dispatch(loadApplication(themeName));

const AppWithThemeClass = () => {
  React.useEffect(() => {
    document.body.classList.add(`theme-${classThemeName}`);
    return () => {
      document.body.classList.remove(`theme-${classThemeName}`);
    };
  }, [classThemeName]);

  return <App />;
};

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <AppWithThemeClass />
        </MuiThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
