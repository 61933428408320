import { FormControl, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import RenderCheckbox from "components/shared/form/RenderCheckbox";
import RenderTextField from "components/shared/form/RenderTextField";
import React from "react";
import { Field } from "redux-form";
import FilterWrapper, { FilterWrapperBorderProps } from "./shared/FilterWrapper";
import { isVanfCleefProcess } from "helpers/constants";

const SearchBar: React.FC<FilterWrapperBorderProps> = ({ borderBottom }) => {
  const isVanCleef = isVanfCleefProcess();
  let hasStrictKeyword = false;

  if (isVanCleef) {
    hasStrictKeyword = true;
  }

  return (
    <FilterWrapper borderBottom={borderBottom}>
      <div className="search-bar">
        <FormControl>
          <Field
            component={RenderTextField}
            name="keyword"
            placeholder="Search"
            variant="outlined"
            size="small"
            type="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        {isVanCleef && (
          <div className="tooltip">
            <div className="tooltip__hovered">
              Separate keywords with commas.
            </div>
            <div className="circle-icon">i</div>
          </div>
        )}
      </div>
      {!isVanCleef && (
        <div className="keyword-explain">
          <p className="">
            Add quotes to search the exact keyword(s)
          </p>
          <p className="f-italic">
            Ex. [“cartier” “tutti frutti”] will find all lots containing exactly ‘cartier’ AND ‘tutti frutti’
          </p>
        </div>
      )}
      {hasStrictKeyword && (
        <div className="strict-keyword">
          <FormControl>
            <Field
              component={RenderCheckbox}
              name="search_mode"
              label="Strict keyword search only"
              labelPlacement="end"
            />
          </FormControl>
        </div>
      )}
    </FilterWrapper>
  )
}

export default SearchBar;