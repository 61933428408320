import { Button } from '@material-ui/core';
import Notification from 'components/notification/Notification';
import { useNotificationValues } from 'components/notification/NotificationHooks';
import MainCurrencyButton from 'components/shared/form/MainCurrencyButton/MainCurrencyButton';
import React, { useState } from 'react';
import JewelDuplicatas from './JewelDuplicatas/JewelDuplicatas';
import './duplicatas.scss';
import { DuplicatasTypes } from './useFetchDuplicata';


const Duplicatas: React.FC = () => {
  const { isNotified, notificationMessage, messageType } = useNotificationValues();
  const [duplicataType, setDuplicataType] = useState<DuplicatasTypes>(DuplicatasTypes.DUPLICATA);

  const isActiveClass = (typeButton: DuplicatasTypes): string => duplicataType === typeButton ? 'vca-btn--primary' : 'vca-btn--secondary';

  return (
    <div className="page-duplicatas vca-inner-t">
      <Notification
        open={isNotified}
        message={notificationMessage}
        messageType={messageType}
      />
      <div className="vca-container-page-mw w-100pct">
        <div className="secondary-filter-header mb-20px">
          <div className="wrap-btn left">
            <Button
              className={`vca-btn vca-btn vca-btn--large ${isActiveClass(DuplicatasTypes.DUPLICATA)}`}
              variant="contained"
              onClick={() => setDuplicataType(DuplicatasTypes.DUPLICATA)}
            >
              {"Duplicatas"}
            </Button>
            <Button
              className={`vca-btn vca-btn vca-btn--large ${isActiveClass(DuplicatasTypes.SOLD_TWICE)}`}
              variant="contained"
              onClick={() => setDuplicataType(DuplicatasTypes.SOLD_TWICE)}
            >
              {"Sold Twice"}
            </Button>
          </div>
          <div className="wrap-btn right">
            <MainCurrencyButton />
          </div>
        </div>
      </div>
      {duplicataType === DuplicatasTypes.DUPLICATA && (
      <JewelDuplicatas duplicataType={DuplicatasTypes.DUPLICATA} />
      )}
      {duplicataType === DuplicatasTypes.SOLD_TWICE && (
      <JewelDuplicatas duplicataType={DuplicatasTypes.SOLD_TWICE} />
      )}
      {/* <JewelDuplicatas duplicataType={duplicataType} setDuplicataType={setDuplicataType} /> */}
    </div>
  );
};

export default Duplicatas;