import { FormControl } from "@material-ui/core";
import RenderAutoComplete, { RenderAutoCompleteProps } from "components/shared/form/RenderAutoComplete";
import React from "react";
import { Field } from "redux-form";
import FilterWrapper, { FilterWrapperBorderProps } from "../FilterWrapper";
import { FilterCheckboxListProps } from "models/Filters.interface";

interface FilterAutocompleteProps extends FilterWrapperBorderProps, RenderAutoCompleteProps {
  storeValuePrefix: string;
  title?: string;
  placeholder?: string;
  isDisabled?: boolean;
}

const FilterAutocomplete: React.FC<FilterAutocompleteProps> = ({
  borderBottom,
  isDisabled,
  storeValuePrefix,
  placeholder,
  recordList,
  title,
  multiple
}) => {
  const options = Object.values(recordList).map((value: FilterCheckboxListProps) => value.paramName);

  return (
    <FilterWrapper borderBottom={borderBottom}>
      {title && (
        <h3 className="heading-3">{title}</h3>
      )}
      <FormControl>
        <Field
          component={RenderAutoComplete}
          multiple={multiple}
          name={storeValuePrefix}
          placeholder={placeholder}
          variant="outlined"
          options={options}
          disabled={isDisabled}
          recordList={recordList}
        />
      </FormControl>
    </FilterWrapper>
  )
}

export default FilterAutocomplete;