import { makeStyles } from "@material-ui/core";

export const notificationStyles = makeStyles((theme) => {
  return {
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: "white",
    },
    info: {
      backgroundColor: theme.palette.info.main,
      color: "white",
    },
    warning: {
      backgroundColor: theme.palette.grey.main,
      color: "white",
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  };
});
