export enum ReusedOriginsNames {
  AUSTRALIA = "Australia",
  BRAZIL = "Brazil",
  BURMA = "Burma (Myanmar)",
  CEYLON = "Ceylon (Sri Lanka)",
  COLOMBIA = "Colombia"
}

export const originsListNames: Array<string> = [
  "Afghanistan",
  "Africa",
  "Akoya",
  ReusedOriginsNames.AUSTRALIA,
  ReusedOriginsNames.BRAZIL,
  ReusedOriginsNames.BURMA,
  "Baltic",
  "Cambodia",
  ReusedOriginsNames.CEYLON,
  ReusedOriginsNames.COLOMBIA,
  "East Africa",
  "Ethiopia",
  "India",
  "Kashmir",
  "Kenya",
  "Madagascar",
  "Mediterranean Sea",
  "Mexico",
  "Mozambique",
  "Nigeria",
  "Russia",
  "Siam",
  "South Sea",
  "Tahiti",
  "Tajikistan",
  "Tanzania",
  "Thaïland",
  "Vietnam",
  "West Africa",
  "Zambia",
  "Zimbabwe",
]