import React  from 'react';

interface HistoryTooltipProps {
  title: any;
  history: any;
}

const HistoryTooltip: React.FC<HistoryTooltipProps> = ({ title, history }) => {
  return (
    <div className="tooltip tooltip--top-right">
      <div className="tooltip__hovered">
        <div className="individual-history">
          <h3 className="individual-history-title heading-4">{title}:</h3>
          {history
            ?.slice(0)
            .reverse()
            .map((manualHistory: any, index: number) => (
              <div className="individual-history-block" key={index}>
                <div className="individual-history-number">{manualHistory[1]}</div>
                <div className="individual-history-info">
                  By <span className="name">{manualHistory[2]}</span> on{" "}
                  <span className="date">
                    {new Date(manualHistory[0]).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>{" "}
                  at{" "}
                  <span className="date">
                    {new Date(manualHistory[0]).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: false,
                    })}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="circle-icon">i</div>
    </div>
  );
};

export default HistoryTooltip;
