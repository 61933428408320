export function exportExcel(dataExport) {
  var list = JSonObjectToCSV(dataExport);
  downloadData(list, "productList");
}

function JSonObjectToCSV(list) {
  if (list.length !== 0) {
    var firstElement = list[0];
    var iterable = Object.keys(firstElement).slice(1);
    var firstLigne = iterable.join(getSeperator());
    var lignes = [];
    lignes.push(firstLigne);
    for (var i = 0; i < list.length; i++) {
      var data = list[i];
      var ligne = "";
      for (var j = 0; j < iterable.length; j++) {
        ligne += data[iterable[j]] + getSeperator();
      }
      lignes.push(ligne.slice(0, -1));
    }
  } else {
    alert("Echec export : Liste des produits vide");
    return;
  }
  return lignes.join("\r\n");
}

function getSeperator() {
  return ";";
}

function downloadData(text, filename) {
  var a = document.createElement("a");
  a.style.display = "none";
  var blob = new Blob([text], { type: "text/csv;charset=utf-8;" });
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename + ".csv";
  a.click();
  window.URL.revokeObjectURL(url);
}

export const formatDate = (date) => {
  let formatted_date = "";
  if (date) {
    const d = new Date(date);
    const options = {year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'};
    formatted_date = d.toLocaleDateString('en-US', options);
  }
  return formatted_date;
}

export const isBoolean = (value) => typeof value === "boolean";

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}