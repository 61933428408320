import React from 'react';
import './stone-admin.scss';

const StonesAdmin: React.FC<any> = ({ stonesInfo }) => {

  const displayTreatmentValue = (value: any) => {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return ''; 
  }
 
  return (
    <div className="stone-admin-row">
      {stonesInfo?.map((s: any, index: number) => (
        <div key={index} className="stone-admin-col">
          <div className="stone-admin-col__group">
            <div>stone:</div>
            <div>{s.stone}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>carat:</div>
            <div>min: {s.carat?.min}<br />
              max: {s.carat?.max}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>jewels:</div>
            <div>{s.jewels}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>measures:</div>
            <div>{s.measures?.map((m: any, i: number) => (
              <div key={i}>
                <b>unit:</b> {m.unit}<br />
                <b>x1:</b> {m.x1}<br />
                <b>x2:</b> {m.x2}<br />
                <b>x3:</b> {m.x3}<br />
              </div>
            ))}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>color:</div>
            <div>
              min: {s.color?.min}<br />
              max: {s.color?.max}
            </div>
          </div>
          <div className="stone-admin-col__group">
            <div>color grade:</div>
            <div>
              min: {s.color_grade?.min}<br />
              max: {s.color_grade?.max}
            </div>
          </div>
          <div className="stone-admin-col__group">
            <div>cuts:</div>
            <div>{s.cuts}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>clarity:</div>
            <div>{s.clarity}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>reports:</div>
            <div>
              name: {s.reports?.length > 0 && s.reports[0].name}<br />
              no: {s.reports?.length > 0 && s.reports[0].no}
            </div>
          </div>
          <div className="stone-admin-col__group">
            <div>origin:</div>
            <div>{s.origin}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>treatment:</div>
            <div>{s.treatment?.map((t: any, i: number) => (
              <div key={i}>
                treated: {t.treated ? 'true' : 'false'}<br />
                value:  {displayTreatmentValue(t.value)}<br />
              </div>
            ))}</div>
          </div>
          <div className="stone-admin-col__group">
            <div>number:</div>
            <div>{s.number}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StonesAdmin