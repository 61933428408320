import React from "react";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";

const RenderSelectDatePicker = ({
  input,
  label,
  name,
  variant,
  minDate,
  maxDate,
  views,
  formatDate
}) => {
  function handleDate(value) {
    input.onChange(value);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <KeyboardDatePicker
        autoOk
        name={name}
        label={label}
        variant={variant}
        minDate={minDate}
        maxDate={maxDate}
        placeholder={formatDate.toLowerCase()}
        value={input.value || null}
        format={formatDate}
        views={views}
        onChange={(value) => handleDate(value)}
      />
    </MuiPickersUtilsProvider>
  );
};
RenderSelectDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  defaultValue: PropTypes.shape({}),
  input: PropTypes.shape({}),
  formatDate: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderSelectDatePicker.defaultProps = {
  label: "",
  variant: "outlined",
  openTo: "",
  formatDate: "dd/MM/yyyy",
  views: undefined,
  defaultValue: undefined,
};

export default RenderSelectDatePicker;
