import { awsRepoImg } from "helpers/constants";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import noImage from "../_assets/no-image.png";

export const getJewelImage = (jewel: JewelDto): string => {
  if (jewel?.image?.length > 0 && jewel?.cluster_hash) {
    return awsRepoImg(jewel?.cluster_hash, jewel?.image[0]?.path)
  } else {
    return noImage;
  }
}

export const getImageUrlOrDefault = (url: string) => (url ?? noImage)