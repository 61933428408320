import { isVanfCleef } from "helpers/constants";
import { CurrenciesValues } from "models/Store/Jewels/Currency.interface";
import { ProductStoreSearch } from "models/Store/ProductStore.interface";
import { getProductStoreSearchMapper } from "models/Store/ProductStoreMapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeNameSelector } from "store/application/ApplicationSelectors";
import { SAVE_SEARCH_FORM_DATA, getSearchProductsList } from "store/product/ProductActions";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import { getDefaultCurrency } from "utils/currency";

export const useMainCurrencyButton = () => {
  const themeName = useSelector(themeNameSelector);
  const isVanCleef = isVanfCleef(themeName);

  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(getDefaultCurrency(isVanCleef));

  const searchFormData: ProductStoreSearch = useSelector(searchFormDataSelector);
  const infoData = (): ProductStoreSearch => getProductStoreSearchMapper(searchFormData, isVanCleef);

  
  const handleMainCurrency = (currency: CurrenciesValues, requestProductList: boolean | undefined) => {
    const data = infoData();
    setCurrency(currency);
    localStorage.setItem(
      "vanCleef_main_currency",
      currency
    );

    if (requestProductList) {
      dispatch(
        getSearchProductsList.request({
          ...data,
          page: 1,
          currency,
        })
      );
    } else {
      dispatch({ type: SAVE_SEARCH_FORM_DATA, ...searchFormData, currency });
    }
  }



  return {
    currency,
    searchFormData,
    handleMainCurrency
  };
};
