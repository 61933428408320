import React, { useEffect } from "react";
import { reduxForm, Field, reset, change, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, FormControl } from "@material-ui/core";
import validate from "../../shared/validated-form/LoginValidation";
import RenderTextField from "../../shared/form/RenderTextField";
import {
  resetPassword
} from "../../../store/application/ApplicationActions";

let ForgotPasswordReset = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, token, email } = props
  

  useEffect(() => {
    dispatch(change("FORGOT_PASSWORD_RESET_FORM", "token", token));
    dispatch(change("FORGOT_PASSWORD_RESET_FORM", "email", email));
  });


  return (
    <div className="">
      <form className="vca-form" onSubmit={handleSubmit} autoComplete="off">
        <h3>Reset password</h3>
        <p>Update your password</p>
        <br />
        <div className="vca-form-line">
          <FormControl>
            <Field
              disabled
              component={RenderTextField}
              name="email"
              placeholder="Email"
              variant="outlined"
            />
          </FormControl>
          <Link className="change-email" to={`/forgot-password/`}>
            Not you? Change email.
          </Link>
        </div>
        <div className="vca-form-line">
          <FormControl>
            <Field
              component={RenderTextField}
              name="password"
              placeholder="New Password"
              variant="outlined"
              type="password"
            />
          </FormControl>
        </div>
        <div className="vca-form-line" style={{ display: "none" }}>
          <FormControl>
            <Field
              component={RenderTextField}
              name="token"
              placeholder="token"
              variant="outlined"
            />
          </FormControl>
        </div>

        <div className="vca-form-line">
          <Button
            className="vca-btn w-100pct"
            variant="contained"
            type="submit"
          >
            Update password
          </Button>
        </div>
      </form>
    </div>
  );
};

const formConfig = {
  form: "FORGOT_PASSWORD_RESET_FORM",
  validate,
  onSubmit: (values, dispatch) => {
    dispatch(resetPassword.request(values));
    dispatch(reset("FORGOT_PASSWORD_RESET_FORM"));
  },
};

ForgotPasswordReset = reduxForm(formConfig)(ForgotPasswordReset)

// Decorate with connect to read form values
const selector = formValueSelector('FORGOT_PASSWORD_RESET_FORM') // <-- same as form name
ForgotPasswordReset = connect(state => {
  const email = selector(state, 'email');

  return {
    emailField: email
  }
})(ForgotPasswordReset)




export default ForgotPasswordReset;
