import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import RenderCheckbox from "components/shared/form/RenderCheckbox";
import { MultiSelectRecord, FilterCheckboxListProps } from "models/Filters.interface";
import { SelectAllCheckboxProps } from "models/Forms/Forms.interface";
import { ProductStoreFilterCheckbox } from "models/Store/ProductStore.interface";
import React, { ChangeEvent, useCallback } from "react";
import { useSelector } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import AccordionWrapper from "../AccordionWrapper";
import FilterWrapper, { FilterWrapperBorderProps } from "../FilterWrapper";
import { useFilterCheckbox } from "./useFilterCheckbox";

interface FilterCheckboxPropsSelectAll extends FilterWrapperBorderProps {
  checkboxList: MultiSelectRecord,
  storeValuePrefix: string;
  storeFormName: string;
  checkboxesStateChanges?: SelectAllCheckboxProps;
  labelSelectAll?: string;
  isDisabled?: boolean;
}

interface FilterCheckboxProps extends FilterCheckboxPropsSelectAll, FilterWrapperBorderProps {
  title: string;
  selectAll?: boolean;
  noWrapper?: boolean;
}


const FilterCheckbox: React.FC<FilterCheckboxProps> = (props: FilterCheckboxProps) => {

  const { borderBottom, noWrapper } = props;
  const CheckboxComponent = (
    <FilterCheckboxAccordion
      {...props} />
  );

  return noWrapper ? CheckboxComponent : (
    <FilterWrapper borderBottom={borderBottom}>
      {CheckboxComponent}
    </FilterWrapper>
  );
}

const FilterCheckboxAccordionSelectAll: React.FC<FilterCheckboxPropsSelectAll> = ({
  checkboxList,
  storeValuePrefix,
  checkboxesStateChanges,
  labelSelectAll,
  isDisabled,
  storeFormName
}) => {
  const { allChecked, handleSelectAll } = useFilterCheckbox({
    checkboxesStateChanges: checkboxesStateChanges,
    checkboxList: checkboxList,
    storeValuePrefix: storeValuePrefix+'.',
    storeFormName: storeFormName
  })

  return (
    <FormControl>
      <FormControlLabel
        labelPlacement="start"
        className="f-italic"
        control={
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectAll(event.target.checked)}
            checked={allChecked.isSelected}
            indeterminate={allChecked.isIndeterminate}
            disabled={isDisabled}
          />
        }
        label={labelSelectAll}
      />
    </FormControl>
  )
}


const FilterCheckboxAccordion: React.FC<FilterCheckboxProps> = ({
  title,
  checkboxList,
  storeValuePrefix,
  checkboxesStateChanges,
  selectAll,
  labelSelectAll = "Select All",
  isDisabled,
  storeFormName,
}) => {

  const selector = formValueSelector(storeFormName); 
  const storeValue = useSelector(state => selector(state, storeValuePrefix));

  const getAmountBoxChecked = useCallback((obj: ProductStoreFilterCheckbox) => {
    return obj && Object.values(obj)?.filter((val: boolean) => val === true).length
  }, [storeValue]);

  return (
    <AccordionWrapper title={title} amountBoxChecked={getAmountBoxChecked(storeValue)}>
      <FormControl>
        {selectAll && (
          <FilterCheckboxAccordionSelectAll
            checkboxList={checkboxList}
            storeValuePrefix={storeValuePrefix}
            checkboxesStateChanges={checkboxesStateChanges}
            labelSelectAll={labelSelectAll}
            isDisabled={isDisabled}
            storeFormName={storeFormName}
          />
        )}
        {
          Object.values(checkboxList)
            .map((checkbox: FilterCheckboxListProps, index: number) => (
              <Field
                component={RenderCheckbox}
                name={`${storeValuePrefix}.${checkbox.paramName}`}
                label={checkbox.label}
                labelPlacement="start"
                key={index}
                isDisabled={isDisabled}
              />
            ))}
      </FormControl>
    </AccordionWrapper>
  )
}

export default FilterCheckbox;