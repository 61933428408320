import { isObjectEmpty } from "helpers/utils";
import { MultiSelectRecord } from "models/Filters.interface";
import { RequestParamName } from "store/models/Request.enum";

export const mapRequestParam = (paramName: RequestParamName, paramValue: string): string => paramValue ? `${paramName}=${paramValue}&` : ``;

export const mapStoreCheckboxRequestParams =<T>(
  productStore: T,
  list: MultiSelectRecord,
  requestParam: RequestParamName
  ): string => {
  if (isObjectEmpty(productStore)) {
    return '';
  } else {

    const sourcesArray: Array<string | number | boolean> = [];
    
    Object.entries(productStore).forEach(([key, value]) => {
      if (value) {
        sourcesArray.push(list[key].dtoValue.toString());
      }
    });
    
    return sourcesArray.length > 0 ? mapRequestParam(requestParam, sourcesArray.toString()) : "";
  }
}
