import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const RenderNumberField = ({
  placeholder,
  label,
  inputRef,
  input,
  classTextfield,
  ...other
}) => {

  const {onChange} = input;
  const _onChange = (e) => {
    if(e?.target){
      onChange(e.target?.value)
    }
  }
  return (
    <div className={`vca-textfield ${classTextfield}`}>
      {label && label.length > 0 && (
        <label className="vca-textfield__label">{label}</label>
      )}
      <div className="vca-textfield__field">
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          value={input.value}
          onValueChange={(_, e) => _onChange(e?.event)}
          thousandSeparator=","
          decimalSeparator="."
          className="some"
          inputMode="numeric"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

RenderNumberField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

RenderNumberField.defaultProps = {
  placeholder: "",
  label: "",
};

export default RenderNumberField;
