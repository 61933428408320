import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import application from "store/application/ApplicationReducers";
import product from "store/product/ProductReducers"

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    application,
    product,
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(history)({application: {themeName: state.application.themeName}}, action); //remove everything but themename
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
