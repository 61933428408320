import { all, takeLatest, put, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
	APPLICATION_IS_READY,
	LOGOUT,
	getTokenProfile,
	GET_TOKEN_PROFILE,
	hideNotification,
  emailForgotPassword,
	GET_TOKEN_FORGOT_PASSWORD,
	getTokenForgotPassword,
	RESET_PASSWORD,
	resetPassword,
	showNotification,
	ERROR,
	SHOW_NOTIFICATION,
	getThemeName,
} from "./ApplicationActions";
import HTTPClient from "../../httpClient/dataClient";
import { handleRequest } from "../helpers/sagasHelpers";

function* handleClientInitiated() {
	console.info("app ready");
	yield put(getThemeName());
}
export function* closeNotification() {
	yield delay(3000);
	yield put(hideNotification());
}

// LOGIN
function* handleGetTokenProfileRequest(values) {
	try {
		yield* handleRequest(
			getTokenProfile,
			HTTPClient.createClient().post(`/login`, {
				email: values.username,
				password: values.password,
			})
		);
	} catch (error) {
		return { Error: true, result: error };
	}
}

function* handleGetTokenProfileResponse(requestResponse) {
	if (requestResponse.payload.token) {
		localStorage.setItem(
			"vanCleef_app_token",
			requestResponse?.payload?.token
		);
		yield put(push("category"));
	} else {
		if (requestResponse.payload.error) {
			yield put(
				showNotification({
					payload: {
						messageType: ERROR,
						message: "Invalid email or password",
					},
				})
			);
		}
	}
}

// LOGOUT
function* handleLogout() {
	localStorage.removeItem("vanCleef_app_token");
	yield put(push("login"));
}

// FORGOT PASSWORD TOKEN
function* handleGetTokenForgotPasswordRequest(values) {
	try {
		yield* handleRequest(
			getTokenForgotPassword,
			HTTPClient.createClient().post(`/password/get-token`, {
				email: values.email,
				redirect_to: values.redirect_to,
			})
		);
	} catch (error) {
    console.error(error)
		return { Error: true, result: error };
	}
}

function* handleGetTokenForgotPasswordResponse(requestResponse) {
	if (requestResponse.payload.error) {
		yield put(
			showNotification({
				payload: {
					messageType: ERROR,
					message: requestResponse.payload.error,
				},
			})
		);
	} else if (requestResponse.payload.user.email) {
    yield put(emailForgotPassword(requestResponse.payload.user.email));
	}
}

// RESET PASSWORD
function* handleResetPasswordRequest(values) {
	try {
		yield* handleRequest(
			resetPassword,
			HTTPClient.createClient().post(`/password/reset`, {
				email: values.email,
				token: values.token,
				password: values.password,
			})
		);
	} catch (error) {
		return { Error: true, result: error };
	}
}

function* handleResetPasswordResponse(requestResponse) {
  yield put(emailForgotPassword(null));
	if (requestResponse.payload.error) {
		yield put(
      showNotification({
        payload: {
          messageType: ERROR,
					message: `${requestResponse.payload.error} Please enter your email again`,
				},
			})
      );
    yield put(push("reset-password"));
	} else {
    yield put(push("reset-password?success=true"));
	}
}

export default function* () {
	yield all([
		takeLatest(APPLICATION_IS_READY, handleClientInitiated),
		takeLatest(LOGOUT, handleLogout),
		takeLatest(GET_TOKEN_PROFILE.REQUEST, handleGetTokenProfileRequest),
		takeLatest(GET_TOKEN_PROFILE.SUCCESS, handleGetTokenProfileResponse),
		takeLatest(
			GET_TOKEN_FORGOT_PASSWORD.REQUEST,
			handleGetTokenForgotPasswordRequest
		),
		takeLatest(
			GET_TOKEN_FORGOT_PASSWORD.SUCCESS,
			handleGetTokenForgotPasswordResponse
		),
		takeLatest(RESET_PASSWORD.REQUEST, handleResetPasswordRequest),
		takeLatest(RESET_PASSWORD.SUCCESS, handleResetPasswordResponse),
		takeLatest(SHOW_NOTIFICATION, closeNotification),
	]);
}
