import { Button } from "@material-ui/core";
import React from "react";
import { DuplicataUserValues } from "../Duplicata.interfaces";

interface ActionsDuplicatasProps {
  handlePostDuplicate: (type: DuplicataUserValues) => void,
  setCurrentJewelInList: (nextJewelInList: number) => void,
  currentJewelInList: number,
  disabled: boolean
}

const ActionsDuplicatas: React.FC<ActionsDuplicatasProps> = ({
  handlePostDuplicate,
  setCurrentJewelInList,
  currentJewelInList,
  disabled
}) => {
  return (
    <div className="vca-container-page">
      <div className="wrap-btn space-around mb-10px">
        <Button
          disabled={disabled}
          className="vca-btn vca-btn--secondary secondary vca-btn--small keep-button"
          variant="outlined"
          onClick={() => handlePostDuplicate(DuplicataUserValues.KEEP_A)}
        >
          {"Keep left"}
        </Button>
        <Button
          disabled={disabled}
          className="vca-btn vca-btn--secondary secondary vca-btn--small button-sold-twice"
          variant="outlined"
          onClick={() => handlePostDuplicate(DuplicataUserValues.NOT_DUPLICATED)}
        >
          {"Not the same creation"}
        </Button>
        <Button
          disabled={disabled}
          className="vca-btn vca-btn--secondary secondary vca-btn--small keep-button"
          variant="outlined"
          onClick={() => handlePostDuplicate(DuplicataUserValues.KEEP_B)}
        >
          {"Keep Right"}
        </Button>
      </div>
      <div className="wrap-btn center mb-10px">
        <Button
          disabled={disabled}
          className="vca-btn vca-btn--secondary secondary vca-btn--small button-sold-twice"
          variant="outlined"
          onClick={() => handlePostDuplicate(DuplicataUserValues.SOLD_TWICE)}
        >
          {"Same creation, sold twice"}
        </Button>
      </div>
      <div className="wrap-btn center">
        <Button
          disabled={disabled}
          className="vca-btn vca-btn--secondary vca-btn--xsmall"
          variant="outlined"
          onClick={() => handlePostDuplicate(DuplicataUserValues.PROBLEM)}
        >
          {"problem"}
        </Button>
        <Button
          disabled={disabled}
          className="vca-btn vca-btn--secondary vca-btn--xsmall"
          variant="outlined"
          onClick={() => setCurrentJewelInList(currentJewelInList + 1)}
        >
          {"pass"}
        </Button>
      </div>
    </div>
  )
}


export default ActionsDuplicatas;