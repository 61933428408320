import { ProductStoreFilterCheckbox, ProductStoreStone } from "models/Store/ProductStore.interface";
import { RequestParamName } from "store/models/Request.enum";
import { mapRequestParam, mapStoreCheckboxRequestParams } from "./ProductRequestMapper";
import { ClarityNames, getClarityListRecord } from "models/Store/Jewels/Stones/ClarityList";
import { getColorGradesListRecord } from "models/Store/Jewels/Stones/ColorGradeList";
import { FilterCheckboxListProps } from "models/Filters.interface";


export const stoneRequestParams = ({
  stone_name,
  treated,
  carat: {
    carat_min,
    carat_max,
  },
  cut,
  origins,
  colors,
  colorGrade,
  clarity,
  certifications
}: ProductStoreStone): string => {

  let requestStone = "";
  // Treated
  requestStone += treated ? `${RequestParamName.TREATED}=0&` : ``;//if treated is selected, send 0 to the server, otherwise don't send the argument

  //name
  requestStone += mapRequestParam(RequestParamName.STONE_NAME, stone_name);

  //carat
  requestStone += stone_name && carat_min ? mapRequestParam(RequestParamName.CARAT_MIN, carat_min) : "";
  requestStone += stone_name && carat_max ? mapRequestParam(RequestParamName.CARAT_MAX, carat_max) : "";

  //origin
  requestStone += stone_name && origins ? mapRequestParam(RequestParamName.ORIGINS, origins.toString()) : "";

  //colors
  requestStone += stone_name && colors ? mapRequestParam(RequestParamName.COLORS, colors.toString()) : "";
  requestStone += stone_name ? mapStoreCheckboxRequestParams(colorGrade, getColorGradesListRecord(), RequestParamName.COLORGRADE) : "";

  //clarity
  requestStone += stone_name && clarity ? getClarityRequestParams(clarity) : "";

  //cuts
  requestStone += stone_name && cut ? mapRequestParam(RequestParamName.CUTS, cut.toString()) : "";

  // certifications
  requestStone += stone_name && certifications ? mapRequestParam(RequestParamName.CERTIFICATIONS, certifications.toString()) : "";

  return requestStone;

}



const getClarityRequestParams = (clarity: ProductStoreFilterCheckbox): string => {
  const clarityListRecord = getClarityListRecord();
  const otherParam = Object.values(clarityListRecord).find(({label}: FilterCheckboxListProps) => label === ClarityNames.OTHER)
  const newClarity = {...clarity};
  let other = '';
  if(clarity[otherParam.paramName]) {
    delete newClarity[otherParam.paramName]
    other = 'SI,SI1,SI2,SI3,I,I1,I2,I3,P,P1,P2,P3'
  }
  return mapStoreCheckboxRequestParams(newClarity, clarityListRecord, RequestParamName.CLARITY)+other;
}
