import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import RenderNumberField from "components/shared/form/RenderNumberField";
import React from "react";
import { Field } from "redux-form";
import FilterWrapper, { FilterWrapperBorderProps } from "./shared/FilterWrapper";
import { useSelector } from "react-redux";
import { currencySelector } from "store/product/ProductSelectors";

const FilterPrice: React.FC<FilterWrapperBorderProps> = ({
  borderBottom
}) => {
  const mainCurrency = useSelector(currencySelector);
  return (
    <FilterWrapper borderBottom={borderBottom}>
      <h3 className="heading-3">Price</h3>
      <div className="search-panel__double-row">
        <FormControl>
          <Field
            component={RenderNumberField}
            name="price.price_min"
            placeholder="Min"
            customInput={TextField}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {mainCurrency === "usd" ? " $" : " €"}
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl>
          <Field
            component={RenderNumberField}
            name="price.price_max"
            placeholder="Max"
            customInput={TextField}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {mainCurrency === "usd" ? " $" : " €"}
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
    </FilterWrapper>
  )
}

export default FilterPrice;