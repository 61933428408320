import { ProductStore } from "models/Store/ProductStore.interface";

const isValidDate = (d: Date) => {
  return d instanceof Date;
};

export const validateSearchPanel = (values: ProductStore) => {
  const errorsOnForm: any = {
    price:{},
    date: {},
    date_manufacture: {},
    stone: {}
  };
  if (
    values?.date?.date_min !== undefined &&
    values?.date?.date_min !== null &&
    !isValidDate(values?.date?.date_min)
  ) {
    errorsOnForm.date.date_min = "Invalid Date";
  }
  if (
    values?.date?.date_max !== undefined &&
    values?.date?.date_max !== null &&
    !isValidDate(values?.date?.date_max)
  ) {
    errorsOnForm.date.date_max = "Invalid Date";
  }
  if (
    values?.date_manufacture?.date_manufacture_min !== undefined &&
    values?.date_manufacture?.date_manufacture_min !== null &&
    !isValidDate(values?.date_manufacture?.date_manufacture_min)
  ) {
    errorsOnForm.date_manufacture.date_manufacture_min = "Invalid Date";
  }
  if (
    values?.date_manufacture?.date_manufacture_max !== undefined &&
    values?.date_manufacture?.date_manufacture_max !== null &&
    !isValidDate(values?.date_manufacture?.date_manufacture_max)
  ) {
    errorsOnForm.date_manufacture.date_manufacture_max = "Invalid Date";
  }
  /* if (
    values?.stone?.stone_name === undefined &&
    (values?.stone?.carat?.carat_min !== undefined || values?.stone?.carat?.carat_max !== undefined)
  ) {
    errorsOnForm.stone.stone_name = "You must enter a stone if you have carats";
  } */
  return errorsOnForm;
};