export enum CreationTypesNames {
  Tiara = "Tiara",
}

export const creationTypesListNames: Array<string> = [
  "Necklace",
  "Ring",
  "Bracelet",
  "Brooch",
  "Earrings",
  CreationTypesNames.Tiara,
  "Vanity case",
]