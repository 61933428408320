import axios from "axios";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

const commonOptions: any = {
  baseURL: null,
  headers: {},
};

class Client {
  client: any = null;
  options = {};
  constructor(customOptions = {}) {
    this.options = merge(commonOptions, customOptions);
    this.client = axios.create(this.options);
    if (localStorage.getItem("vanCleef_app_token")) {
      this.client.interceptors.request.use(async (paramConfig: any) => {
        const config = cloneDeep(paramConfig);
        config.headers.Authorization = `Token ${localStorage.getItem(
          "vanCleef_app_token"
        )}`;
        return config;
      });
    }
  }

  getInstance() {
    return this.client;
  }
}

export default Client;
