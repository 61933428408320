export enum ColorsNames {
  YELLOW = "yellow",
  BLUE = "blue",
  PINK = "pink",
  ORANGE = "orange"
}

export const colorsListNames: Array<string> = [
  ColorsNames.BLUE,
  "violet",
  "purple",
  ColorsNames.PINK,
  "red",
  ColorsNames.ORANGE,
  "brown",
  ColorsNames.YELLOW,
  "green",
  "black",
  "white",
  "gray",
  "padparadscha",
]