import { ProductStoreSearch } from "models/Store/ProductStore.interface";
import { getProductStoreSearchMapper } from "models/Store/ProductStoreMapper";
import {
  createRequestTypes,
  generateRequestActions,
} from "../helpers/actionsHelpers";
import { JewelResultsDto } from "models/Dto/JewelsDto.interface";

export const GET_SEARCH_PRODUCTS_LIST: any = createRequestTypes(
  "GET_SEARCH_PRODUCTS_LIST"
);
export const getSearchProductsList = generateRequestActions(
  GET_SEARCH_PRODUCTS_LIST
);

export const GET_PRODUCT_INFO: any = createRequestTypes("GET_PRODUCT_INFO");
export const getProductInfo = generateRequestActions(GET_PRODUCT_INFO);

export const GET_PRINT_PRODUCTS: any = createRequestTypes("GET_PRINT_PRODUCTS");
export const getPrintProducts = generateRequestActions(GET_PRINT_PRODUCTS);

export const SAVE_SEARCH_FORM_DATA = "SAVE_SEARCH_FORM_DATA";

export const saveSearchFormData = ({ data }: {data: ProductStoreSearch}) => {
  return {
    type: SAVE_SEARCH_FORM_DATA,
    ...getProductStoreSearchMapper(data)
  }
};


export const SAVE_PRODUCTS_REQUESTS_HISTORY = "SAVE_PRODUCTS_REQUESTS_HISTORY";

export const saveProductsRequestsHistory = ({ payload }: {payload: JewelResultsDto}) => {
  return {
    type: SAVE_PRODUCTS_REQUESTS_HISTORY,
    payload
  }
};


export const SAVE_PRODUCTS_LIST = "SAVE_PRODUCTS_LIST";
export const saveProductsList = ({ payload }: {payload: JewelResultsDto}) => {
  return {
    type: SAVE_PRODUCTS_LIST,
    payload
  }
};