import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "models/Store/ProductStore.utils";
import { colorsListNames } from "./ColorsNames";


export const getColorsListRecord = (): MultiSelectRecord => createRecordFromNames(colorsListNames);

/* const suggestedColorsNames: Array<string> = [
  ColorsNames.YELLOW,
  ColorsNames.BLUE,
  ColorsNames.PINK,
  ColorsNames.ORANGE,
];
 */