import { ProductStorePrice } from "models/Store/ProductStore.interface";
import { mapRequestParam } from "./ProductRequestMapper";
import { RequestParamName } from "store/models/Request.enum";


export const priceRequestParams = (price: ProductStorePrice):string => {
  const requestPriceMin = mapRequestParam(RequestParamName.PRICE_MIN, price.price_min);
  const requestPriceMax = mapRequestParam(RequestParamName.PRICE_MAX, price.price_max);

  return `${requestPriceMin}${requestPriceMax}`
}