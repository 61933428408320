import { isVanfCleefProcess } from "helpers/constants";
import { JewelDto, JewelSourceTypeLabelDto, JewelStatusLabelDto } from "models/Dto/JewelsDto.interface";

export const isJewelStatusSold = (status: JewelStatusLabelDto): boolean =>
  status === JewelStatusLabelDto.SOLD;

export const isJewelStatusNotSold = (status: JewelStatusLabelDto): boolean =>
  status === JewelStatusLabelDto.NOTSOLD;

export const isJewelStatusForSale = (status: JewelStatusLabelDto): boolean =>
  status === JewelStatusLabelDto.FORSALE;

export const isMarketplace = (sourceType: JewelSourceTypeLabelDto): boolean =>
  sourceType === JewelSourceTypeLabelDto.MARKETPLACE;

export const getStatusLabel = (product: JewelDto): string =>
  (isVanfCleefProcess() && isMarketplace(product.source_type) && isJewelStatusNotSold(product.status)) ?
    JewelStatusLabelDto.WITHDRAWN : product.status

