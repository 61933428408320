import React from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { isFetchingSelector } from "../../store/application/ApplicationSelectors";

function LoadingIndicator() {
  const isLoadingObj = useSelector(isFetchingSelector);
  const isLoading = Object.values(isLoadingObj).reduce(
    (prev, curr) => prev || curr,
    false
  );

  return (
    isLoading && (
      <div className="loading-container">
        <h1 className="loading-text" data-text={"Chargement"}>
          {"Chargement"}
        </h1>
      </div>
    )
  );
}

export default LoadingIndicator;
