import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "models/Store/ProductStore.utils";
import { stonesListNames } from "./StonesNames";


export const getStonesListRecord = (): MultiSelectRecord => createRecordFromNames(stonesListNames);

/* const suggestedStonesNames: Array<string> = [
  StonesNames.DIAMOND,
  StonesNames.EMERALD,
  StonesNames.RUBY,
  StonesNames.SAPPHIRE,
  StonesNames.AQUAMARINE,
];
 */