import { useSelectAllCheckbox } from "hooks/forms/useSelectAllCheckbox";
import { MultiSelectRecord, FilterCheckboxListProps } from "models/Filters.interface";
import { SelectAllCheckboxProps } from "models/Forms/Forms.interface";
import { useDispatch } from "react-redux";
import { change } from "redux-form";

interface UseFilterCheckboxProp {
  checkboxesStateChanges: SelectAllCheckboxProps;
  checkboxList: MultiSelectRecord;
  storeValuePrefix: string;
  storeFormName: string;
}

export const useFilterCheckbox = ({
    checkboxesStateChanges,
    checkboxList,
    storeValuePrefix,
    storeFormName
  }: UseFilterCheckboxProp
) => {
  const dispatch = useDispatch();
  const { allChecked, onCheckAll: onCheckAllCreationType } = useSelectAllCheckbox({ checkboxState: checkboxesStateChanges })

  const handleSelectAll = (isChecked: boolean) => {
    onCheckAllCreationType(isChecked);
    selectAllStoreCheckboxes(isChecked, checkboxList, storeValuePrefix)
  };

  const selectAllStoreCheckboxes = (
    isChecked: boolean,
    list: MultiSelectRecord,
    storeParam: string
  ) => {
    Object.values(list)?.forEach(({paramName}: FilterCheckboxListProps) => {
      dispatch(change(storeFormName, `${storeParam}${paramName}`, isChecked))
    })
  }

  return {
    allChecked,
    handleSelectAll
  }
}