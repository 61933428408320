import { defaultStrictKeywords } from "components/models/Keywords";
import { isVanfCleefProcess } from "helpers/constants";
import { ProductStoreSearch, ProductStoreTotalState } from "models/Store/ProductStore.interface";
import { getProductStoreSearchMapper } from "models/Store/ProductStoreMapper";
import { Reducer } from "redux";
import {
  GET_PRINT_PRODUCTS,
  GET_PRODUCT_INFO,
  GET_SEARCH_PRODUCTS_LIST,
  SAVE_PRODUCTS_LIST,
  SAVE_PRODUCTS_REQUESTS_HISTORY,
  SAVE_SEARCH_FORM_DATA,
} from "./ProductActions";
import { mapProductsRequestsHistory } from "models/Store/ProductsRequestsHistoryStoreMapper";



const getInitialState = (): ProductStoreTotalState => {
  const initialSearchForm = getProductStoreSearchMapper(undefined, isVanfCleefProcess());
  initialSearchForm.search_mode = defaultStrictKeywords();

  return {
    productsList: [],
    productsRequestHistory: new Map(),
    productInfo: {},
    searchFormData: initialSearchForm
  }
};

const product: Reducer<ProductStoreTotalState, any> = (state: ProductStoreTotalState = getInitialState(), action: any = {}) => {
  switch (action.type) {
    case GET_PRODUCT_INFO.SUCCESS:
      return {
        ...state,
        productInfo: action.payload,
      };
    case GET_SEARCH_PRODUCTS_LIST.SUCCESS:
    case SAVE_PRODUCTS_LIST:
      return {
        ...state,
        productsList: action.payload,
      };
    case SAVE_SEARCH_FORM_DATA: {
      const searchFormData: ProductStoreSearch = getProductStoreSearchMapper(action)
      return {
        ...state,
        searchFormData: searchFormData,
      };
    }
    case GET_PRINT_PRODUCTS.SUCCESS: {
      console.info(action.payload);
      return {
        ...state,
      };
    }
    case SAVE_PRODUCTS_REQUESTS_HISTORY: {
      const productsRequestHistory = state.productsRequestHistory
      const newHistory = mapProductsRequestsHistory(productsRequestHistory, action.payload)
      return {
        ...state,
        productsRequestHistory: newHistory,
        productsList: action.payload
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default product;
