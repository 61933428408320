import PriceTooltip from "components/shared/price-tooltip/PriceTooltip";
import StringDefaulter from "helpers/StringDefaulter";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import './price-block.scss';
import { mainCurrencies } from "utils/currency";
import { CurrenciesValues } from "models/Store/Jewels/Currency.interface";

interface PriceBlockProps {
  jewel: JewelDto | undefined;
  title: string;
  valueSource: number | undefined;
  valueCurrency?: number | undefined;
  alternativeText?: string | undefined;
  valueSourceSecondary?: number;
  valueCurrencySecondary?: number;
}

const PriceBlock: React.FC<PriceBlockProps> = ({
  jewel,
  title,
  valueSource,
  valueCurrency,
  alternativeText,
  valueSourceSecondary,
  valueCurrencySecondary,
}) => {
  const searchFormData = useSelector(searchFormDataSelector);

  const [isCurrencyEur, setIsCurrencyEur] = useState(true);

  const getCurrencyBase = (): string => isCurrencyEur ? mainCurrencies[CurrenciesValues.EUR].label : mainCurrencies[CurrenciesValues.USD].label;

  useEffect(() => {
    setIsCurrencyEur(!searchFormData.currency || searchFormData.currency === 'eur')
  }, [searchFormData]);

  let discount = null;
  if (
    (jewel?.status === "Sold" &&
      title === "Final Sale Price" &&
      jewel?.discount) ||
    (jewel?.status !== "Sold" &&
      title === "Current Price for Sale" &&
      jewel?.discount)
  ) {
    const discountMark = jewel.discount > 0 ? "+" : "";
    discount = `(${discountMark}${jewel.discount} %)`;
  }

  let hideCurrentPrice = false;
  if (
    title === "Current Price for Sale" &&
    jewel?.status === "Sold"
  ) {
    hideCurrentPrice = true;
  }

  return (
    <div className="price-block">
      <div className="title heading-2">{title}</div>
      <div className="price heading-2 fc-light">
        {valueCurrency && !hideCurrentPrice && (
          <>
            <div className="price-eur">
              {StringDefaulter.defaultPrice(
                valueCurrency,
                getCurrencyBase(),
                alternativeText
              )}
              {valueCurrencySecondary && (
                <>
                  {" "}-{" "}
                  {StringDefaulter.defaultPrice(
                    valueCurrencySecondary,
                    getCurrencyBase(),
                    "-"
                  )}
                </>
              )}{" "}
              {discount}
              <PriceTooltip tooltipposition="right" />
            </div>
            <div className="estimated-price">
              (
              {StringDefaulter.defaultPrice(
                valueSource,
                jewel?.currency,
                alternativeText
              )}
              {valueCurrencySecondary && (
                <>
                  {" "}-{" "}
                  {StringDefaulter.defaultPrice(
                    valueSourceSecondary,
                    jewel?.currency,
                    "-"
                  )}
                </>
              )}
              )
            </div>
          </>
        )}
        {!valueCurrency && !hideCurrentPrice && (
          <div className="price-eur">
            {StringDefaulter.defaultPrice(
              valueSource,
              jewel?.currency,
              alternativeText
            )}{" "}
            {discount}
          </div>
        )}
        {hideCurrentPrice && <div className="price-eur">-</div>}
      </div>
    </div>
  );
}

export default PriceBlock;