export enum CutNames {
  BRILLIANTROUND = "Brilliant / Round",
  CABOCHON = "Cabochon",
  CUSHION = "Cushion",
  EMERALD = "Emerald",
}

export const cutListNames: Array<string> = [
  "Asscher",
  "Baguette",
  CutNames.BRILLIANTROUND,
  "Briolette",
  CutNames.CABOCHON,
  CutNames.CUSHION,
  CutNames.EMERALD,
  "Heart",
  "Marquise / Navette",
  "Oval",
  "Pear / Drop",
  "Princess",
  "Radiant",
  "Rose",
  "Square",
  "Other",
]