import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ForgotPasswordReset from "../components/forgot-password/forgot-password-reset/ForgotPasswordReset";
import { themeNameSelector } from "../store/application/ApplicationSelectors";
import { useNotificationValues } from "../components/notification/NotificationHooks";
import Notification from "../components/notification/Notification";

import { getLogo } from "../helpers/constants";

const ResetPassword = () => {
  const themeName = useSelector(themeNameSelector);
  const { isNotified, notificationMessage, messageType } = useNotificationValues();
  const logo = getLogo(themeName);

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const token = queryParameters.get("token");
  const email = queryParameters.get("email");
  const isSuccess = queryParameters.get("success");
  let hasError = false;
  if ((!token || !email) && !isSuccess) {
    hasError = true;
  }

  return (
    <div className="login-wrapper">
      <Notification
        open={isNotified}
        message={notificationMessage}
        messageType={messageType}
      />
      <div className="login-logo">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      {hasError && (
        <div>
          <h3>An error occured</h3>
          <p>
            Please re-enter your email:
            <Link to={`/forgot-password/`}>Re-enter your email</Link>
          </p>
        </div>
      )}
      {(!hasError && !isSuccess) && <ForgotPasswordReset email={email} token={token} />}
      {isSuccess && (
        <div>
          <h3>Password udpdated</h3>
          <p>
            Your password has been successfully updated.<br></br>
          </p>
        </div>
      )}
      <br />
      <br />
      <br />
      <Link className="forgot-password" to="/login">
        Back To Login
      </Link>
    </div>
  );
};

export default ResetPassword;
