import Client from "./client";

class HTTPClient {

  static createClient() {
    const api_db_vitrine = "/api/"; // API Url set in public/_redirects file
    const api_db_luxe = "/api2/"; // API Url set in public/_redirects file
    const api_es = "/api3/"; // API Url set in public/_redirects file
    let baseURL = "https://backend.pm-advisory.com:8046/api/"; // Default value used for localhost or non-netlify deployments

    if (process.env.REACT_APP_NETLIFY && process.env.REACT_APP_NETLIFY !== "false") {
      baseURL = api_db_luxe; // Default value

      if (process.env.REACT_APP_BACKEND) {
        if (process.env.REACT_APP_BACKEND === "db_luxe") {
          baseURL = api_db_luxe;
        } else if (process.env.REACT_APP_BACKEND === "db_vitrine") {
          baseURL = api_db_vitrine;
        } else if (process.env.REACT_APP_BACKEND === "db_es") {
          baseURL = api_es;
        }
      } else {
        // Old websites without the 2nd environment variables
        if (process.env.REACT_APP_THEME !== "vancleefdbluxe") {
          baseURL = api_db_vitrine;
        }
      }
    }

    return new Client({
      baseURL: baseURL,
      headers: {},
      body: {},
    }).getInstance();
  }
}

export default HTTPClient;
