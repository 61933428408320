import { useDispatch } from "react-redux";
import { logout } from "../store/application/ApplicationActions";

export const useLayout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return { handleLogout };
};
